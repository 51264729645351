import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CampaignDetailsChartProps, CampaignEmission, RechartsComponentData } from './types'
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import VariableUtils from 'utils/variable'
import DateUtils from 'utils/date'
import { DATE_FORMAT } from 'constant'
import FillingSpinner from 'components/FillingSpinner'
import FlexCol from 'components/Layout/FlexCol'
import FlexRow from 'components/Layout/FlexRow'
import Colors from 'styles/colors.module.scss'
import debounce from 'lodash/debounce'
import './CampaignDetailsChart.scss'
import { CampaignTarget } from '../../../../types/campaign'
import ChartSupportService from './chart-support.service'
import CampaignDetailsChartService from './campaign-details-chart.service'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import CampaignDetailsChartFilters from '../CampaignDetailsChartFilters/CampaignDetailsChartFilters'

const DEBOUNCE_GET_DELAY = 2000 // [ms]

const CampaignDetailsChart: React.FC<CampaignDetailsChartProps> = ({ publicViewProps }) => {
  const { t } = useTranslation()
  const { emissions, loading } = useSelector((state: RootState) => state.campaignDetails.chart)
  const { displayedMediaLoaded, campaignData, filters, audienceFilters } = useSelector(
    (state: RootState) => state.campaignDetails
  )
  const [focusBar, setFocusBar] = useState<number | null>(null)
  const debouncedFetchImpressions = useCallback(
    debounce(CampaignDetailsChartService.fetchImpressions, DEBOUNCE_GET_DELAY),
    []
  )
  const isAudienceCampaign = campaignData?.target === CampaignTarget.AUDIENCE

  useEffect(() => {
    if (!displayedMediaLoaded || !campaignData) {
      return
    }

    debouncedFetchImpressions(
      campaignData.id,
      publicViewProps,
      filters.selected,
      audienceFilters.selected
    )
  }, [filters.selected, audienceFilters.selected])

  const ChartContainer = (
    emissions: CampaignEmission[],
    target: CampaignTarget | undefined
  ): ReactElement => {
    const label =
      target === CampaignTarget.IMPRESSIONS ? 'common.numberOfEmissions' : 'common.numberOfAudience'
    const legend =
      target === CampaignTarget.IMPRESSIONS ? 'common.numberOfEmissions' : 'common.numberOfAudience'

    const estimatedAudienceLegend = 'common.numberOfAudienceEstimated'

    return (
      <>
        <div className='CampaignDetailsChart__label'>{t(label)}</div>

        {Chart(emissions, target)}

        <div className='CampaignDetailsChart__legend'>
          <div className='CampaignDetailsChart__legend--item'>
            <div className='CampaignDetailsChart__legend--symbol CampaignDetailsChart__legend--symbol-emitted-views' />
            <div>{t(legend)}</div>
          </div>
          {isAudienceCampaign && (
            <div className='CampaignDetailsChart__legend--item'>
              <div className='CampaignDetailsChart__legend--symbol CampaignDetailsChart__legend--symbol-estimated-audience' />
              <div>{t(estimatedAudienceLegend)}</div>
            </div>
          )}
        </div>
      </>
    )
  }

  const Chart = (emissions: CampaignEmission[], target?: CampaignTarget): ReactElement => {
    const dataKey = target === CampaignTarget.IMPRESSIONS ? 'emissions' : 'audience'
    const yAxisId = target === CampaignTarget.IMPRESSIONS ? 'emissionsY' : 'audienceY'

    return (
      <div className='CampaignDetailsChart__chart'>
        <ResponsiveContainer height={320}>
          <ComposedChart
            throttleDelay={100}
            data={emissions}
            maxBarSize={30}
            onMouseMove={({ isTooltipActive, activeTooltipIndex }): void => {
              const payload =
                isTooltipActive && activeTooltipIndex !== undefined ? activeTooltipIndex : null

              if (payload === focusBar) {
                return
              }

              setFocusBar(payload)
            }}
          >
            <CartesianGrid
              stroke={Colors.gray2}
              strokeDasharray='2 2'
            />
            <XAxis
              dataKey='date'
              interval='preserveStartEnd'
              stroke={Colors.gray4}
              padding={{ right: 5 }}
              tick={XaxisTick}
            />
            <YAxis
              dataKey={dataKey}
              yAxisId={yAxisId}
              orientation='left'
              stroke={Colors.gray4}
              padding={{ top: 15 }}
              tickLine={false}
              tick={(props): ReactElement => YaxisTick(props, -37)}
            />
            <Tooltip
              cursor={false}
              content={CustomTooltip}
              offset={10}
            />
            <Bar
              dataKey={dataKey}
              yAxisId={yAxisId}
              radius={[2, 2, 0, 0]}
            >
              {emissions.map((campaignEmission, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={ChartSupportService.getCellColor(target, campaignEmission, index, focusBar)}
                />
              ))}
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  }

  const XaxisTick = ({ x, y, payload: { value } }: RechartsComponentData): ReactElement => (
    <g
      className='CampaignDetailsChart__axis-tick'
      transform={`translate(${x},${y})`}
    >
      <text
        x={0}
        y={0}
        dx={-28}
        dy={17}
        textAnchor='start'
      >
        {DateUtils.parseAndFormat(value, DATE_FORMAT)}
      </text>
    </g>
  )

  const YaxisTick = (
    { x, y, payload: { value } }: RechartsComponentData,
    customX: number
  ): ReactElement => (
    <g
      className='CampaignDetailsChart__axis-tick'
      transform={`translate(${x},${y})`}
    >
      <text
        x={customX}
        y={0}
        dy={3}
        textAnchor={'start'}
      >
        {value > 0 ? VariableUtils.formatNumberCompact(value) : ''}
      </text>
    </g>
  )

  const CustomTooltip = ({ active, payload, label }: TooltipProps<any, any>): ReactNode => {
    if (!active) {
      return null
    }

    const date = label as string
    const data: CampaignEmission = payload![0].payload
    const audienceMode = campaignData?.target === CampaignTarget.AUDIENCE

    const topTranslation = ChartSupportService.getTooltipTopTranslationKey(
      audienceMode,
      data.estimated
    )
    const topValue = audienceMode ? data.audience : data.emissions

    const bottomTranslation = ChartSupportService.getTooltipBottomTranslationKey(
      audienceMode,
      data.estimated
    )
    const bottomValue = audienceMode ? data.totalAudience : data.totalEmissions

    const emissionsTranslation = 'common.numberOfEmissions'
    const emissionsValue = data.emissions
    const totalEmissionsTranslation = 'campaignDetails.chart.sumOfEmissions'
    const totalEmissionsValue = data.totalEmissions

    return (
      <div className='CampaignDetailsChart__tooltip'>
        <div className='CampaignDetailsChart__tooltip--header'>
          {DateUtils.parseAndFormat(date, DATE_FORMAT)}
        </div>
        <FlexRow className='CampaignDetailsChart__tooltip--row'>
          <FlexCol className='CampaignDetailsChart__tooltip--col'>{t(topTranslation)}:</FlexCol>
          <FlexCol className='CampaignDetailsChart__tooltip--col'>
            {VariableUtils.formatNumber(topValue)}
          </FlexCol>
        </FlexRow>
        <FlexRow className='CampaignDetailsChart__tooltip--row'>
          <FlexCol className='CampaignDetailsChart__tooltip--col'>{t(bottomTranslation)}:</FlexCol>
          <FlexCol className='CampaignDetailsChart__tooltip--col'>
            {VariableUtils.formatNumber(bottomValue)}
          </FlexCol>
        </FlexRow>
        {audienceMode && (
          <>
            <FlexRow className='CampaignDetailsChart__tooltip--row'>
              <FlexCol className='CampaignDetailsChart__tooltip--col'>
                {t(emissionsTranslation)}:
              </FlexCol>
              <FlexCol className='CampaignDetailsChart__tooltip--col'>
                {VariableUtils.formatNumber(emissionsValue)}
              </FlexCol>
            </FlexRow>
            <FlexRow className='CampaignDetailsChart__tooltip--row'>
              <FlexCol className='CampaignDetailsChart__tooltip--col'>
                {t(totalEmissionsTranslation)}:
              </FlexCol>
              <FlexCol className='CampaignDetailsChart__tooltip--col'>
                {VariableUtils.formatNumber(totalEmissionsValue)}
              </FlexCol>
            </FlexRow>
          </>
        )}
      </div>
    )
  }

  return (
    <div className='CampaignDetailsChart'>
      <CampaignDetailsChartFilters />

      {loading ? (
        <FillingSpinner className='CampaignDetailsChart--loading' />
      ) : emissions.length > 0 ? (
        ChartContainer(emissions, campaignData?.target)
      ) : (
        <div className='CampaignDetailsChart__no-data'>{t('campaignDetails.chart.noData')}</div>
      )}
    </div>
  )
}

export default CampaignDetailsChart
