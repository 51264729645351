import { BasicsValues, DetailsValues } from 'contexts/CampaignFormContext'
import VariableUtils from 'utils/variable'
import { Agencies, BasicFormErrors, BasicFormErrorsEmpty } from './types'
import DateUtils from 'utils/date'
import { TFunction } from 'i18next'
import api from 'api'
import { OnInputChangeFn } from 'components/Form/Input'
import { Alert, Campaign, CampaignAcceptanceStatus, CampaignStatus } from 'types/campaign'
import EnumUtils from 'utils/enum'
import { CardStatus } from 'components/Layout/Card/types'
import { AGENCIES_LIMIT, INPUT_LIST_LIMIT } from 'constant'
import { SelectAsyncValue } from 'components/Form/SelectAsync'
import ErrorUtils from 'utils/error'
import { UseAuthorizeProps } from 'hooks/useAuthorize'
import { Agency, AllowedTarget } from 'types/agency'
import { CAMPAIGN_ACTION, CAMPAIGN_FORM } from 'constant/authorization'
import { Dispatch, SetStateAction } from 'react'
import { Routes } from 'routes'
import { NavigateFunction } from 'react-router-dom'
import { BaseRole, Role, User, UserSortColumn, UserStatus } from 'types/user'
import { SortDirection } from 'types/various'
import { userFullName } from 'utils/user'
import { AllowedForArgs } from '../../../../hooks/useAuthorize/types'
import DetailsFormService from '../DetailsForm/services/details-form.service'
import { store } from '../../../../store'
import { campaignActions } from '../../store/campaign-form-slice'
import { Company } from '../../../../api/companies/types'
import { ActionButtonsHelper } from '../../../../utils/action-buttons-helper'
import { detailsFormActions } from '../DetailsForm/store/details-form-slice'

export default class BasicsFormService {
  static hasErrors = (errors: BasicFormErrors | undefined): boolean =>
    !VariableUtils.isDeepEqual(errors, BasicFormErrorsEmpty)

  static validate = (
    values: BasicsValues,
    allowedFor: UseAuthorizeProps['allowedFor'],
    setErrors: Dispatch<SetStateAction<BasicFormErrors>>,
    errors: BasicFormErrors,
    t: TFunction
  ): void => {
    const newErrors = BasicsFormService.getErrors(values, allowedFor, t)
    if (VariableUtils.isDeepEqual(newErrors, errors)) return

    setErrors(newErrors)
  }

  static getCompanies = (term: string): Promise<SelectAsyncValue<Company>[]> =>
    api.companies
      .getCompanies(term)
      .then(res =>
        res.data.companies.map(
          (company: Company): SelectAsyncValue<Company> => ({
            value: company.name,
            rawData: company,
          })
        )
      )
      .catch(() => [])

  static getAgencies = (inputValue: string): Promise<SelectAsyncValue<Agencies>[]> =>
    api.agency
      .getAgencies({
        term: inputValue,
        first: AGENCIES_LIMIT,
      })
      .then(res => {
        return res.data.agencies.nodes.map(
          (agency: Agency): SelectAsyncValue<Agency> => ({
            value: agency.name,
            rawData: agency,
          })
        )
      })
      .catch(() => [])

  static handleSubmit = (
    data: BasicsValues,
    onInputChange: OnInputChangeFn,
    navigate: NavigateFunction,
    detailsValues: DetailsValues,
    setTriggerFetchCampaign: Dispatch<SetStateAction<boolean>>,
    userRoles?: Role[]
  ): Promise<void> => {
    store.dispatch(campaignActions.setCardStatus({ basics: CardStatus.SAVING }))
    const { agency, name, briefName, startDate, endDate } = data

    return api.campaign
      .createSketchCampaign({
        agencyId: agency ? agency!.rawData!.id : null,
        name,
        briefName,
        startDate,
        endDate,
      })
      .then(res => {
        if (!res.data) {
          return
        }

        const { id, priority } = res.data.createSketchCampaign.campaign
        onInputChange(id, 'campaignId')

        store.dispatch(campaignActions.setCardStatus({ basics: CardStatus.SAVED }))
        navigate(Routes.CAMPAIGNS.EDIT(id))

        return { id, priority }
      })
      .then((response: { id: string; priority: number } | undefined) => {
        if (!response || !response.id) {
          return
        }

        return DetailsFormService.handleSubmit(
          response.id,
          { ...detailsValues, priority: response.priority },
          setTriggerFetchCampaign,
          userRoles
        )
      })
      .catch(() => void store.dispatch(campaignActions.setCardStatus({ basics: CardStatus.ERROR })))
  }

  static handleUpdate = (data: BasicsValues, t: TFunction, userRoles?: Role[]): Promise<void> => {
    store.dispatch(campaignActions.setCardStatus({ basics: CardStatus.SAVING }))
    const { campaignId, agency, advertiser, name, briefName, startDate, endDate, supervisors } =
      data
    const attachSupervisors =
      userRoles &&
      (userRoles.some(role => role.baseRole === BaseRole.ADMIN) ||
        userRoles.some(role => role.baseRole === BaseRole.SUPPORT))

    if (attachSupervisors) {
      return api.campaign
        .updateCampaign({
          briefName,
          agencyId: agency ? agency!.rawData!.id : null,
          advertiserId: advertiser ? advertiser!.rawData!.id : null,
          endDate,
          id: campaignId,
          name,
          startDate,
          supervisors: BasicsFormService.getSupervisorsIds(supervisors),
        })
        .then(res => {
          const brainState = res.data?.updateCampaign.campaign.brainState
          const newSupervisors = BasicsFormService.supervisorToSelectAsyncValues(
            res.data?.updateCampaign.campaign.supervisors || []
          )

          store.dispatch(
            campaignActions.updateBasicsValues({
              supervisors: newSupervisors,
              ...(brainState && { brainState }),
            })
          )
          store.dispatch(campaignActions.setCardStatus({ basics: CardStatus.SAVED }))

          if (!res.data) {
            return
          }

          this.updateAllowedTargets(res.data.updateCampaign.campaign.agency, t)
        })
        .catch(
          () => void store.dispatch(campaignActions.setCardStatus({ basics: CardStatus.ERROR }))
        )
    }

    return api.campaign
      .updateCampaignWithoutSupervisors({
        briefName,
        agencyId: agency ? agency!.rawData!.id : null,
        advertiserId: advertiser ? advertiser!.rawData!.id : null,
        endDate,
        id: campaignId,
        name,
        startDate,
      })
      .then(res => {
        const brainState = res.data?.updateCampaign.campaign.brainState
        const newSupervisors = BasicsFormService.supervisorToSelectAsyncValues(
          res.data?.updateCampaign.campaign.supervisors || []
        )

        store.dispatch(
          campaignActions.updateBasicsValues({
            supervisors: newSupervisors,
            ...(brainState && { brainState }),
          })
        )
        store.dispatch(campaignActions.setCardStatus({ basics: CardStatus.SAVED }))

        if (!res.data) {
          return
        }

        this.updateAllowedTargets(res.data.updateCampaign.campaign.agency, t)
      })
      .catch(() => void store.dispatch(campaignActions.setCardStatus({ basics: CardStatus.ERROR })))
  }

  static fetchValues = (
    campaignId: Campaign['id'],
    values: BasicsValues,
    setFetchCompleted: Dispatch<SetStateAction<boolean>>,
    setTriggerFetchCampaign: Dispatch<SetStateAction<boolean>>,
    allowedFor: (args: AllowedForArgs) => boolean,
    userRoles: Role[] | undefined,
    navigate: NavigateFunction,
    withAllocatedTime: boolean,
    t: TFunction
  ): void => {
    store.dispatch(campaignActions.setCardStatus({ basics: CardStatus.LOADING }))

    api.campaign
      .getCampaignForBasicForm(campaignId)
      .then(res => {
        // check if user is owner and can edit campaign
        if (!ActionButtonsHelper.getCanEditByOwner(res.data.node.status, withAllocatedTime)) {
          // disallow editing campaign for clients and Campaign with:
          // - Campaign status active and planned
          // - Campaign status sketch and acceptance status pending_acceptation
          if (
            (allowedFor({
              template: CAMPAIGN_ACTION.EDIT_ACTIVE_PLANNED_RESERVED_CAMPAIGN_BY_CLIENT,
              status: res.data.node.status,
            }) ||
              (!allowedFor({
                template: CAMPAIGN_ACTION.EDIT_IN_PENDING_ACCEPTANCE_NOT_BY_CLIENT,
                status: res.data.node.status,
              }) &&
                res.data.node.acceptanceStatus === CampaignAcceptanceStatus.pending_acceptation)) &&
            !userRoles?.some(role => role.baseRole === BaseRole.ADMIN)
          ) {
            return navigate(Routes.NOT_FOUND)
          }
        }

        const {
          alerts,
          aggregatedAcceptanceAlerts,
          brainState,
          briefName,
          agency,
          advertiser,
          endDate,
          name,
          note,
          contractNote,
          reservationTill,
          startDate,
          status,
          supervisors,
          estimatedValue,
          createdAt,
          bundle,
        } = res.data.node

        const newValues = {
          alerts: alerts.filter((alert: Alert) => alert.active),
          aggregatedAcceptanceAlerts: aggregatedAcceptanceAlerts.filter(
            (alert: Alert) => alert.active
          ),
          brainState,
          briefName: briefName ?? '',
          campaignId,
          agency: agency
            ? VariableUtils.toSelectAsyncValue<Agency>({
                value: agency!.name,
                rawData: agency!,
              })
            : null,
          advertiser: advertiser
            ? VariableUtils.toSelectAsyncValue<Company>({
                value: advertiser!.name,
                rawData: advertiser!,
              })
            : null,
          endDate: endDate ?? '',
          name,
          note,
          contractNote,
          reservationTill: reservationTill ? new Date(reservationTill) : null,
          startDate: startDate ?? '',
          status:
            CampaignStatus[
              EnumUtils.getKeyByValue(CampaignStatus, status) as keyof typeof CampaignStatus
            ],
          supervisors: BasicsFormService.supervisorToSelectAsyncValues(supervisors),
          estimatedValue: estimatedValue,
          createdAt,
          bundle,
        }

        store.dispatch(campaignActions.updateBasicsValues({ ...values, ...newValues }))
        setFetchCompleted(true)
        setTriggerFetchCampaign(false)
        store.dispatch(campaignActions.setCardStatus({ basics: CardStatus.NONE }))

        const targetOptions = agency.allowedTargets.map((target: AllowedTarget) => ({
          value: target.name,
          label: t(`common.${target.name}.b`),
        }))
        store.dispatch(detailsFormActions.setTargetOptions(targetOptions))
      })
      .catch(() => {
        store.dispatch(campaignActions.setCardStatus({ basics: CardStatus.ERROR }))
      })
  }

  static getUsersForSupervisors = (input: string): Promise<SelectAsyncValue<User>[]> =>
    api.user
      .getUsers({
        term: input,
        first: INPUT_LIST_LIMIT,
        sortColumn: UserSortColumn.FIRST_NAME,
        sortDirection: SortDirection.ASC,
        filters: { statuses: [UserStatus.ACTIVE, UserStatus.SUBSCRIPTION] },
      })
      .then(res => BasicsFormService.supervisorToSelectAsyncValues(res.data.users.nodes))
      .catch(() => [])

  private static getErrors = (
    values: BasicsValues,
    allowedFor: UseAuthorizeProps['allowedFor'],
    t: TFunction
  ): BasicFormErrors => {
    const startDate = new Date(values.startDate)
    const endDate = new Date(values.endDate)
    const hasStartAndEndDate = values.startDate.length && values.endDate.length
    const maxNameLength = 30

    return {
      agency: [
        ...(allowedFor({ template: CAMPAIGN_FORM.AGENCY, status: values.status })
          ? ErrorUtils.isRequired(values.agency?.value ?? '')
          : []),
      ],
      name: [
        ...ErrorUtils.isRequired(values.name),
        ...ErrorUtils.maxLength(values.name, maxNameLength),
      ],
      briefName: [
        ...ErrorUtils.isRequired(values.briefName),
        ...ErrorUtils.maxLength(values.briefName, maxNameLength),
      ],
      startDate: [
        ...(hasStartAndEndDate && DateUtils.isAfterDay(startDate, endDate)
          ? [t('form.startDateAfter')]
          : ErrorUtils.isRequired(values.startDate)),
      ],
      endDate: [
        ...(hasStartAndEndDate && DateUtils.isBeforeDay(endDate, startDate)
          ? [t('form.endDateBefore')]
          : ErrorUtils.isRequired(values.endDate)),
      ],
    }
  }

  private static getSupervisorsIds = (supervisors: SelectAsyncValue<User>[]): string[] =>
    supervisors.map((sav: SelectAsyncValue<User>) => sav.rawData!.id)

  private static supervisorToSelectAsyncValues = (users: User[]): SelectAsyncValue<User>[] =>
    users.map(
      (u): SelectAsyncValue<User> => ({
        value: u.id,
        label: userFullName(u),
        rawData: u,
      })
    )

  private static updateAllowedTargets = (
    agency: Pick<Campaign['agency'], 'allowedTargets'>,
    t: TFunction
  ) => {
    const targetOptions = agency.allowedTargets.map((target: AllowedTarget) => ({
      value: target.name,
      label: t(`common.${target.name}.b`),
    }))
    store.dispatch(detailsFormActions.setTargetOptions(targetOptions))
  }
}
