import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BasicsForm from './BasicsForm'
import Card, { CardStatus } from 'components/Layout/Card'
import DetailsForm from './DetailsForm'
import PageHeader from 'components/Layout/PageHeader'
import CreationsForm from './CreationsForm'
import { CampaignFormContext } from 'contexts/CampaignFormContext'
import Summary from './Summary'
import { CampaignFormHashes, CampaignFormProps } from './types'
import { TFunction } from 'i18next'
import LiveMediaMap from './LiveMediaMap'
import CampaignActions from './CampaignActions'
import { FormType } from 'types/various'
import { DEBOUNCE_GET_DELAY_DEFAULT } from 'constant'
import { useLocation } from 'react-router-dom'
import WindowUtils from 'utils/window'
import { AppContext } from 'contexts/AppContext'
import { CAMPAIGN, CAMPAIGN_ACTION } from 'constant/authorization'
import debounce from 'lodash/debounce'
import useFilteredMedia from 'hooks/useFilteredMedia'
import AlertsCard from './AlertsCard'
import EstimatedEmissionsSummary from './EstimatedEmissionsSummary'
import NoteSummary from '././NoteSummary'
import './CampaignForm.scss'
import { useSelector } from 'react-redux'
import { RootState, store } from '../../../store'
import { campaignActions } from '../store/campaign-form-slice'
import { CampaignPermissionType, FeatureName } from 'types/features'
import { CampaignTarget } from '../../../types/campaign'
import EstimatedValue from './EstimatedValue'
import { BillingsHelper } from '../../../utils/billings-helper'
import EstimatedAudienceEmissions from './EstimatedAudienceEmissions'

const CampaignForm: React.FC<CampaignFormProps> = ({ formType }) => {
  const { t } = useTranslation()
  const { allowedFor, userData } = useContext(AppContext)
  const { creationsValues } = useContext(CampaignFormContext)
  const { fetchCampaignMedia, mediaLoading, media } = useFilteredMedia()
  const location = useLocation()
  const [isCreationsCardCollapsed, setIsCreationsCardCollapsed] = useState<boolean>(false)
  const { basicsValues, detailsValues } = useSelector((state: RootState) => state.campaignForm.form)
  const { basics: basicsCardStatus, details: detailsCardStatus } = useSelector(
    (state: RootState) => state.campaignForm.cardStatus
  )
  const creationsCardStatus = useSelector(
    (state: RootState) => state.campaignForm.cardStatus.creations
  )

  const campaignId = basicsValues.campaignId
  const campaignStatus = basicsValues.status
  const isNewCampaign = formType === FormType.NEW && campaignId.length === 0
  const canEditCampaign = allowedFor({
    template: CAMPAIGN_ACTION.EDIT,
    feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Write },
    status: campaignStatus,
  })
  const { brainState } = basicsValues

  const canEditNote = allowedFor({
    template: CAMPAIGN_ACTION.EDIT_NOTE,
    status: campaignStatus,
  })

  useEffect(() => {
    if (location.hash && creationsCardStatus === CardStatus.NONE) {
      const el = document.getElementById(location.hash.substring(1))
      if (el) WindowUtils.scrollWithOffset(el)
    }
  }, [basicsCardStatus, detailsCardStatus, creationsCardStatus])

  const debouncedFetchMedia = useCallback(
    debounce(fetchCampaignMedia, DEBOUNCE_GET_DELAY_DEFAULT),
    []
  )

  useEffect(() => {
    if (isNewCampaign) {
      debouncedFetchMedia({ editCampaign: true })
    } else if (campaignId.length > 0) {
      debouncedFetchMedia({ campaignId, editCampaign: true })
    }
  }, [
    formType,
    campaignId,
    detailsCardStatus,
    creationsValues.length,
    debouncedFetchMedia,
    brainState,
  ])

  useEffect(() => {
    if (isNewCampaign) {
      setIsCreationsCardCollapsed(true)
    }
  }, [formType, campaignId])

  useEffect(() => {
    return () => {
      store.dispatch(campaignActions.resetCampaignForm())
    }
  }, [])

  const header = (): TFunction => {
    switch (formType) {
      case FormType.NEW:
        return t('common.newCampaign')
      case FormType.EDIT:
        return t('form.campaign.edit')
    }
  }

  return (
    <>
      <div className='CampaignForm__live-media-map'>
        <LiveMediaMap media={media} />
      </div>

      <div className='CampaignForm'>
        <PageHeader
          className='CampaignForm__header'
          withBorder
        >
          {header()}
        </PageHeader>

        <div className='CampaignForm__summary-card--container'>
          <Card
            className='CampaignForm__summary-card'
            disabled={!canEditCampaign}
          >
            <Summary
              title={t('common.usedMedia')}
              quantity={media.length}
              isLoading={mediaLoading}
              withCircle
              dataCy={'campaign-used-media'}
            />
          </Card>

          <Card
            className='CampaignForm__summary-card'
            disabled={!canEditCampaign}
          >
            <EstimatedEmissionsSummary
              campaignId={campaignId}
              title={'estimatedEmissions'}
              experimental={false}
            />
          </Card>

          {canEditNote && (
            <Card className='CampaignForm__summary-card'>
              <NoteSummary
                campaignId={campaignId}
                note={basicsValues.note}
                contractNote={basicsValues.contractNote}
                onEdit={note => store.dispatch(campaignActions.updateBasicsValues({ note }))}
              />
            </Card>
          )}

          {BillingsHelper.show(
            basicsValues.createdAt,
            basicsValues.bundle,
            userData.withAllocatedTime,
            basicsValues.agency?.rawData?.withAllocatedTime
          ) && (
            <Card className='CampaignForm__summary-card'>
              <EstimatedValue estimatedValue={basicsValues.estimatedValue} />
            </Card>
          )}

          {detailsValues.target === CampaignTarget.AUDIENCE && (
            <Card className='CampaignForm__summary-card'>
              <EstimatedAudienceEmissions
                campaignId={campaignId}
                audience={detailsValues.audience}
                hiddenAudience={detailsValues.hiddenAudience}
              />
            </Card>
          )}

          {allowedFor({ template: CAMPAIGN.ALERTS }) &&
            (basicsValues.alerts.length > 0 ||
              basicsValues.aggregatedAcceptanceAlerts.length > 0) && (
              <AlertsCard
                className='CampaignForm__summary-card CampaignForm__summary-card--alerts'
                alerts={[...basicsValues.alerts, ...basicsValues.aggregatedAcceptanceAlerts]}
              />
            )}
        </div>

        <Card
          dataCy='basic-campaign-info'
          headerNumber={1}
          className='CampaignForm__card CampaignForm__card--basics'
          title={t('form.campaign.basics.header')}
          status={basicsCardStatus}
          disabled={!canEditCampaign}
        >
          <BasicsForm />
        </Card>

        <Card
          headerNumber={2}
          className='CampaignForm__card'
          title={t('common.campaignDetails')}
          status={detailsCardStatus}
          disabled={!campaignId || !canEditCampaign}
        >
          <DetailsForm />
        </Card>

        <Card
          id={CampaignFormHashes.CREATIONS}
          headerNumber={3}
          className='CampaignForm__card'
          title={t('form.campaign.creations.header')}
          status={creationsCardStatus}
          noMarginBody
          isCollapsed={isCreationsCardCollapsed}
          onCollapse={isCollapsed => void setIsCreationsCardCollapsed(isCollapsed)}
          disabled={!campaignId || !canEditCampaign}
        >
          {!isCreationsCardCollapsed && <CreationsForm />}
        </Card>

        {campaignId && (
          <CampaignActions
            campaignData={{
              id: campaignId,
              reservationTill: basicsValues.reservationTill,
              startDate: basicsValues.startDate,
              status: campaignStatus,
            }}
            formType={formType}
          />
        )}
      </div>
    </>
  )
}

export default CampaignForm
