import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import FillingSpinner from 'components/FillingSpinner'
import Button, { ButtonSize, ButtonTheme } from 'components/Form/Button'
import Checkbox from 'components/Form/Checkbox'
import FormRow from 'components/Form/FormRow'
import Input from 'components/Form/Input'
import InputSearch from 'components/Form/InputSearch'
import SelectMultiCheckbox, { MenuPlacement } from 'components/Form/SelectMultiCheckbox'
import { DEBOUNCE_GET_DELAY_DEFAULT } from 'constant'
import useMediaSelection from 'hooks/useMediaSelection'
import {
  agglomerationsFilter,
  buildingsFilter,
  citiesFilter,
  mapFilterToSelectOption,
  mediaFilter,
  poiFilter,
  textFilter,
} from 'hooks/useMediaSelection/filters'
import { sortMediaByAsId } from 'hooks/useMediaSelection/sort'
import { getValidMediaIds, switchStatesByMediaToImport } from 'hooks/useMediaSelection/switcher'
import {
  BrainState,
  GroupData,
  GroupedMedia,
  MediaGroup,
  MediaGroupTemp,
  MediaUsageCounter,
  MediumGroup,
} from 'hooks/useMediaSelection/types'
import { t } from 'i18next'
import debounce from 'lodash/debounce'
import { MediaFormatIndoors, Medium, MediumStatus, OFFER } from 'types/campaign'
import TsxUtils from 'utils/tsx'
import { MediaSelectionProps } from './types'
import VariableUtils from 'utils/variable'
import TemplateSelection from './TemplateSelection'
import './MediaSelection.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../../contexts/AppContext'
import { CAMPAIGN_FORM } from '../../constant/authorization'
import { Features } from '../../constant/features'
import { RootState, store } from '../../store'
import { useSelector } from 'react-redux'
import { campaignActions } from '../../pages/Campaign/store/campaign-form-slice'
import NoVideoIcon from 'images/no_video.svg'
import OfferFormService from '../../pages/Campaign/CampaignForm/DetailsForm/services/offer-form.service'

const MediaSelection: React.FC<MediaSelectionProps> = ({
  ids,
  mediumFormat,
  readOnly,
  withFilters,
  autoSave,
  shouldSave,
  setShouldSave,
  setIsMediaSelectionModalOpen,
  onSave,
  onLoading,
}) => {
  const { basicsValues, detailsValues } = useSelector((state: RootState) => state.campaignForm.form)
  const { status: campaignStatus } = basicsValues

  const {
    counter,
    countTailMedia,
    filters,
    groupedMedia,
    loading,
    mediaUsage,
    onCheckMedium,
    onCheckTail,
    onClearFilters,
    onFilter,
    onFoldTail,
    selectMedia,
    saveMedia,
    selectAllState,
    setSelectAllState,
    setFilters,
    mediumTemplates,
    refetchMediumTemplates,
    templateLoader,
    setGroupedMedia,
  } = useMediaSelection({
    ids,
    mediumFormat,
    readOnly,
    autoSave,
    onLoading,
  })

  const [mediaToImport, setMediaToImport] = useState<string>('')
  const [debouncedMediaToImport, setDebouncedMediaToImport] = useState<string>('')
  const { userData, allowedFor, isFeatureActive } = useContext(AppContext)
  const previousFilters = useRef(filters)
  const templateSelectionRef = useRef(() => null)

  const filtersChanged = (): boolean => !VariableUtils.isDeepEqual(previousFilters.current, filters)

  const mediumGroupSelected = (tailId: string) => {
    templateSelectionRef.current()
    onCheckTail(tailId)
  }
  const selectAll = () => {
    if (!groupedMedia || getValidMediaIds(debouncedMediaToImport)) {
      return
    }
    setSelectAllState(!selectAllState)
    templateSelectionRef.current()
    const allMediaSelectedOrUnselected = JSON.parse(JSON.stringify(groupedMedia), (key, value) => {
      if (key == 'used') {
        return !selectAllState
      }
      return value
    })

    setGroupedMedia(allMediaSelectedOrUnselected)
  }

  const mediumSelected = (mediumId: Medium['id']) => {
    templateSelectionRef.current()
    onCheckMedium(mediumId)
  }

  useEffect(() => {
    if (!shouldSave || !groupedMedia || !mediaUsage) return

    saveMedia(mediaUsage, setShouldSave, setIsMediaSelectionModalOpen).then(
      (brainState?: BrainState): void => {
        if (brainState) store.dispatch(campaignActions.updateBasicsValues({ brainState }))

        if (isFeatureActive(Features.POI)) {
          store.dispatch(
            campaignActions.updateDetailsValues({ pois: [...filters.userSelectedPois] })
          )
        }

        onSave?.(
          mediaUsage
            .filter(mediumUsage => mediumUsage.used)
            .map(filteredMediumUsage => filteredMediumUsage.id)
        )
      }
    )
  }, [shouldSave])

  useEffect(() => {
    if (mediaToImport.trim() && groupedMedia) {
      onClearFilters()
      templateSelectionRef.current()
      switchStatesByMediaToImport(debouncedMediaToImport, groupedMedia, onCheckMedium, onCheckTail)
    }
  }, [debouncedMediaToImport])

  useEffect(() => {
    const numberOfUsedAgglomerations = OfferFormService.numberOfUsedAgglomerations(groupedMedia)
    store.dispatch(campaignActions.setNumberOfUsedAgglomerations(numberOfUsedAgglomerations))
  }, [groupedMedia])

  const updatePreviousFilters = () => {
    previousFilters.current = filters
  }
  const debouncedMediaToImportCallback = useCallback(
    debounce(value => {
      setDebouncedMediaToImport(value)
    }, DEBOUNCE_GET_DELAY_DEFAULT),
    []
  )

  const Container = (groupedMedia: GroupedMedia): ReactNode => (
    <>
      {withFilters && Filters(groupedMedia)}
      {allowedFor({
        template: CAMPAIGN_FORM.MEDIA_SELECTION_FULL_SCOPE,
        status: campaignStatus,
      }) && MediaToImport()}
      {userData.signedIn && (
        <TemplateSelection
          templateSelectionRef={templateSelectionRef}
          templateLoader={templateLoader}
          selectMedia={selectMedia}
          mediumTemplates={mediumTemplates}
          refetchMediumTemplates={refetchMediumTemplates}
        />
      )}
      <div>{Checkboxes(groupedMedia)}</div>
    </>
  )

  const Filters = (groupedMedia: GroupedMedia): ReactNode => (
    <div className='MediaSelection__Filters'>
      <FormRow
        className={
          !allowedFor({
            template: CAMPAIGN_FORM.MEDIA_SELECTION_FULL_SCOPE,
            status: campaignStatus,
          }) && detailsValues.offer === OFFER.BUILDINGS
            ? 'FormRow--buildings-filters'
            : ''
        }
      >
        <SelectMultiCheckbox
          id='agglomeration'
          title={t('common.agglomeration')}
          value={filters.userSelectedAgglomerations}
          options={mapFilterToSelectOption(filters.allAgglomerations)}
          onChange={selected =>
            void setFilters({ ...filters, userSelectedAgglomerations: selected })
          }
          selectAllOption
          onMenuOpen={updatePreviousFilters}
          onMenuClose={() => {
            if (filtersChanged()) {
              setFilters(agglomerationsFilter(groupedMedia, filters))
            }
          }}
        />

        <SelectMultiCheckbox
          id='city'
          title={t('media.city')}
          value={filters.userSelectedCities}
          options={mapFilterToSelectOption(filters.allCities)}
          onChange={selected => void setFilters({ ...filters, userSelectedCities: selected })}
          selectAllOption
          onMenuOpen={updatePreviousFilters}
          onMenuClose={() => {
            if (filtersChanged()) {
              setFilters(citiesFilter(groupedMedia, filters))
            }
          }}
        />

        {MediaFormatIndoors.includes(mediumFormat) && (
          <SelectMultiCheckbox
            id='building'
            title={t('common.building')}
            value={filters.userSelectedBuildings}
            options={mapFilterToSelectOption(filters.allBuildings)}
            onChange={selected => void setFilters({ ...filters, userSelectedBuildings: selected })}
            selectAllOption
            onMenuOpen={updatePreviousFilters}
            onMenuClose={() => {
              if (filtersChanged()) {
                setFilters(buildingsFilter(groupedMedia, filters))
              }
            }}
          />
        )}

        {(allowedFor({
          template: CAMPAIGN_FORM.MEDIA_SELECTION_FULL_SCOPE,
          status: campaignStatus,
        }) ||
          detailsValues.offer !== OFFER.BUILDINGS) && (
          <SelectMultiCheckbox
            id='medium'
            title={t('common.medium')}
            value={filters.userSelectedMedia}
            options={mapFilterToSelectOption(filters.allMedia)}
            onChange={selected => void setFilters({ ...filters, userSelectedMedia: selected })}
            selectAllOption
            menuPlacement={MenuPlacement.RIGHT}
            onMenuOpen={updatePreviousFilters}
            onMenuClose={() => {
              if (filtersChanged()) {
                setFilters(mediaFilter(groupedMedia, filters))
              }
            }}
          />
        )}
      </FormRow>

      <FormRow className={isFeatureActive(Features.POI) ? 'MediaSelection--poi-row' : ''}>
        {(allowedFor({
          template: CAMPAIGN_FORM.MEDIA_SELECTION_FULL_SCOPE,
          status: campaignStatus,
        }) ||
          detailsValues.offer !== OFFER.BUILDINGS) && (
          <>
            {isFeatureActive(Features.POI) && (
              <SelectMultiCheckbox
                id='pois'
                title={t('common.pois')}
                help={t('form.campaign.details.poisHelp')}
                value={filters.userSelectedPois}
                options={mapFilterToSelectOption(filters.allPois)}
                onChange={selected => void setFilters({ ...filters, userSelectedPois: selected })}
                selectAllOption
                onMenuOpen={updatePreviousFilters}
                onMenuClose={() => {
                  if (filtersChanged()) {
                    setFilters(poiFilter(groupedMedia, filters))
                  }
                }}
              />
            )}

            <InputSearch
              id='text'
              value={filters.text}
              placeholder={t('mediaSelection.searchAddress')}
              onChange={text => {
                if (groupedMedia) {
                  setFilters(textFilter(groupedMedia, filters, text))
                }
              }}
              onSubmit={text => void setFilters({ ...filters, text })}
              withButton={false}
            />
          </>
        )}

        {Actions()}
      </FormRow>
    </div>
  )

  const Actions = (): ReactNode => (
    <div className='MediaSelection__Filters--actions'>
      <Button
        size={ButtonSize.SMALL}
        theme={ButtonTheme.BLUE_OUTLINE}
        onClick={() => void onClearFilters()}
      >
        {t('filters.clearFilters')}
      </Button>

      <Button
        size={ButtonSize.SMALL}
        onClick={() => void onFilter(filters)}
      >
        {t('filters.filter')}
      </Button>
    </div>
  )
  const MediaToImport = (): ReactNode => {
    return (
      <div className='MediaSelection__MediaToImport'>
        <Input
          id='media-to-import'
          title={t('mediaSelection.mediaToImport')}
          placeholder={t('mediaSelection.mediaToImportPlaceholder')}
          value={mediaToImport}
          onChange={value => {
            debouncedMediaToImportCallback(value)
            setMediaToImport(value)
          }}
        />
      </div>
    )
  }

  const Checkboxes = (groupedMedia: GroupedMedia): ReactNode => (
    <>
      {!readOnly && SelectAllCheckbox()}
      {Object.values(groupedMedia).map((mediaGroup: MediaGroup) => Tail(mediaGroup))}
    </>
  )

  const Tail = (mediaGroup: MediaGroup, nesting = 0): ReactNode => {
    const {
      group: { id: tailId, name, group: tailGroup, used, folded, hidden },
    } = mediaGroup as GroupData

    if (hidden) return

    return (
      <div
        key={tailId}
        style={nestingStyle(nesting)}
      >
        <div className='MediaSelection__tail--row'>
          {allowedFor({
            template: CAMPAIGN_FORM.MEDIA_SELECTION_FULL_SCOPE,
            status: campaignStatus,
          }) ||
          detailsValues.offer !== OFFER.AGGLOMERATIONS ||
          tailGroup === MediumGroup.AGGLOMERATION ? (
            <Checkbox
              id={tailId}
              checked={used}
              onChange={() => !getValidMediaIds(mediaToImport) && mediumGroupSelected(tailId)}
              readOnly={readOnly}
              disabled={OfferFormService.disableEdit(
                userData.user?.roles,
                detailsValues.offer,
                tailGroup
              )}
            >
              <span
                className='MediaSelection__checkbox--tail'
                data-cy={'media-selection-tail-checkbox'}
              >
                {name}
              </span>

              {tailGroup !== MediumGroup.CITY && ` (${t(`mediumGroup.${tailGroup}`)})`}
            </Checkbox>
          ) : (
            <div style={nestingStyle(nesting)}>
              <span
                className='MediaSelection__checkbox--tail'
                data-cy={'media-selection-tail-checkbox'}
              >
                {name}
              </span>

              {tailGroup !== MediumGroup.CITY && ` (${t(`mediumGroup.${tailGroup}`)})`}
            </div>
          )}

          {Counter(countTailMedia(mediaGroup))}

          {(allowedFor({
            template: CAMPAIGN_FORM.MEDIA_SELECTION_FULL_SCOPE,
            status: campaignStatus,
          }) ||
            detailsValues.offer !== OFFER.BUILDINGS ||
            tailGroup !== MediumGroup.BUILDING) && (
            <FontAwesomeIcon
              icon={folded ? faChevronDown : faChevronUp}
              className='MediaSelection__tail--arrow'
              onClick={() => void onFoldTail(tailId)}
            />
          )}
        </div>

        {!folded &&
          (allowedFor({
            template: CAMPAIGN_FORM.MEDIA_SELECTION_FULL_SCOPE,
            status: campaignStatus,
          }) ||
            detailsValues.offer !== OFFER.BUILDINGS ||
            tailGroup !== MediumGroup.BUILDING) &&
          NestedTail(mediaGroup)}
      </div>
    )
  }

  const NestedTail = (mediaGroup: MediaGroup): ReactNode => {
    const nesting = 33 // px

    return Object.entries(mediaGroup).map(
      ([key, node]: [key: string, node: MediaGroupTemp | Medium]): ReactNode => {
        if (key === 'group') return

        const nodeGroup: MediumGroup | null = (node as MediaGroupTemp).group?.group ?? null
        if (nodeGroup) {
          return Tail(sortMediaByAsId(node as MediaGroupTemp), nesting)
        } else {
          if ((node as MediaGroupTemp).hidden) return

          return MediumCheckbox(node as Medium, nesting)
        }
      }
    )
  }

  const MediumCheckbox = (medium: Medium, nesting: number): ReactNode => {
    const { id, used, status } = medium

    return allowedFor({
      template: CAMPAIGN_FORM.MEDIA_SELECTION_FULL_SCOPE,
      status: campaignStatus,
    }) || detailsValues.offer !== OFFER.AGGLOMERATIONS ? (
      <Checkbox
        id={id}
        key={id}
        style={nestingStyle(nesting)}
        className={
          'MediaSelection__checkbox--medium' +
          TsxUtils.extraStyle(
            status !== MediumStatus.ACTIVE || medium.creationFormat === 'no_video',
            'MediaSelection__checkbox--grayed-out-title'
          )
        }
        checked={used}
        onChange={() => {
          if (medium.creationFormat === 'no_video') return

          return !getValidMediaIds(mediaToImport) && mediumSelected(id)
        }}
        readOnly={readOnly}
        disabled={OfferFormService.disableEdit(userData.user?.roles, detailsValues.offer)}
      >
        {`${medium.asId} - ${medium.address}`}{' '}
        {medium.creationFormat === 'no_video' && (
          <img
            className='MediaSelection__no-video'
            src={NoVideoIcon}
            alt='No video'
          />
        )}
      </Checkbox>
    ) : (
      <div
        style={nestingStyle(66)}
        className={
          'MediaSelection__checkbox--medium' +
          TsxUtils.extraStyle(
            status !== MediumStatus.ACTIVE,
            'MediaSelection__checkbox--grayed-out-title'
          )
        }
      >
        {`${medium.asId} - ${medium.address}`}
      </div>
    )
  }

  const SelectAllCheckbox = (): ReactNode => {
    const id = 'select-all'

    return (
      <Checkbox
        id={id}
        key={id}
        className='MediaSelection__checkbox--medium'
        checked={selectAllState}
        onChange={() => void selectAll()}
        readOnly={readOnly}
      >
        <>
          {t('form.selectAll')}
          {Counter(counter)}
        </>
      </Checkbox>
    )
  }

  const nestingStyle = (nesting: number): CSSProperties => ({
    paddingLeft: `${nesting}px`,
  })

  const Counter = (counter: MediaUsageCounter): ReactNode => (
    <span className='MediaSelection__tail--counter'>{`(${counter.used}/${counter.all})`}</span>
  )

  return (
    <div className={withFilters ? 'MediaSelection' : 'MediaSelection MediaSelection--no-filters'}>
      {loading ? (
        <FillingSpinner className='MediaSelection__spinner' />
      ) : groupedMedia ? (
        Container(groupedMedia)
      ) : (
        <>{t('common.noDataFound')}</>
      )}
    </div>
  )
}

export default MediaSelection
