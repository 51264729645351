import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CampaignFormSliceModel } from '../models/campaign-form-slice.model'
import { basicsValuesDefault, detailsValuesDefault } from '../../../contexts/CampaignFormContext'
import { produce } from 'immer'
import { CardStatus } from '../../../components/Layout/Card'

const initialState: CampaignFormSliceModel = {
  cardStatus: {
    basics: CardStatus.NONE,
    details: CardStatus.NONE,
    creations: CardStatus.NONE,
  },
  form: {
    basicsValues: basicsValuesDefault,
    detailsValues: detailsValuesDefault,
  },
  numberOfUsedAgglomerations: 0,
}

const campaignFormSlice = createSlice({
  name: 'campaignForm',
  initialState,
  reducers: {
    updateBasicsValues(
      state,
      action: PayloadAction<Partial<CampaignFormSliceModel['form']['basicsValues']>>
    ) {
      state.form.basicsValues = produce(state.form.basicsValues, draft => {
        return { ...draft, ...action.payload }
      })
    },
    updateDetailsValues(
      state,
      action: PayloadAction<Partial<CampaignFormSliceModel['form']['detailsValues']>>
    ) {
      state.form.detailsValues = produce(state.form.detailsValues, draft => {
        return { ...draft, ...action.payload }
      })
    },
    resetCampaignForm(state) {
      state.form.basicsValues = basicsValuesDefault
      state.form.detailsValues = detailsValuesDefault
    },
    setCardStatus(state, action: PayloadAction<Partial<CampaignFormSliceModel['cardStatus']>>) {
      state.cardStatus = produce(state.cardStatus, draft => {
        return { ...draft, ...action.payload }
      })
    },
    setNumberOfUsedAgglomerations(state, action: PayloadAction<number>) {
      state.numberOfUsedAgglomerations = action.payload
    },
  },
})

export const campaignActions = campaignFormSlice.actions

export default campaignFormSlice
