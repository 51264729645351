import React, { ReactNode, useContext, useEffect, useState } from 'react'
import {
  basicDropZoneOptions,
  CreationsFormUploadProps,
  DropZoneSize,
  extendedDropZoneOptions,
} from './types'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from 'contexts/CampaignFormContext'
import service from './service'
import { MediaFormat } from 'types/campaign'
import ModalUpload from 'components/Modal/ModalUpload/ModalUpload'
import {
  CREATIONS_MAX_FILE_DIMENSIONS,
  CREATIONS_MAX_FILE_SIZE,
  CREATIONS_MAX_FILES,
} from 'constant'
import VariableUtils from 'utils/variable'
import './CreationsFormUpload.scss'
import { AppContext } from '../../../../../contexts/AppContext'
import { CAMPAIGN_ACTION } from '../../../../../constant/authorization'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store'

const CreationsFormUpload: React.FC<CreationsFormUploadProps> = ({ mediaFormat, onFinish }) => {
  const {
    creationsValues: creations,
    setCreationsValues: setCreations,
    setTriggerCreations,
  } = useContext(CampaignFormContext)
  const { t } = useTranslation()
  const [icon, setIcon] = useState<string | undefined>()
  const { allowedFor, userData } = useContext(AppContext)
  const { basicsValues } = useSelector((state: RootState) => state.campaignForm.form)
  const dropZoneOptions = allowedFor({ template: CAMPAIGN_ACTION.UPLOAD_ZIP })
    ? extendedDropZoneOptions
    : basicDropZoneOptions

  const loadIcon = (icon: string): void => {
    import(`images/${icon}.svg`).then(image => void setIcon(image.default))
  }

  useEffect(() => {
    loadIcon(dropZoneOptions[mediaFormat as MediaFormat].icon)
  }, [mediaFormat])

  const DropZoneRequirements = (mediaFormat: MediaFormat): ReactNode => {
    const dropZoneOption = dropZoneOptions[mediaFormat]

    return !dropZoneOption ? (
      ''
    ) : (
      <div>
        {dropZoneOption.sizes.map((size: DropZoneSize, index) => {
          return (
            <p
              key={index}
              className='CreationsFormUpload__option'
            >
              {`${size.name}`} - <span>{`${size.width} x ${size.height}`} </span>
              {t('common.units.pixels')}
            </p>
          )
        })}
        {dropZoneOption.maxMovieLength && (
          <p className='CreationsFormUpload__option'>
            {t('form.campaign.creations.dropZoneOptions.maxMovieLength')}:{' '}
            <span>{dropZoneOption.maxMovieLength}</span> {t('common.units.seconds')}
          </p>
        )}
      </div>
    )
  }

  const DropHeader = (
    <div className='CreationsFormUpload__header--container'>
      <div className='CreationsFormUpload__header'>
        <img
          className='CreationsFormUpload__header--img'
          src={icon}
          alt={mediaFormat}
        />
        <div className='CreationsFormUpload__header--title'>{t(`mediaType.${mediaFormat}`)}</div>
      </div>

      <div className='CreationsFormUpload__requirements'>
        {DropZoneRequirements(mediaFormat)}

        <div className='CreationsFormUpload__accepted-formats'>
          {`${t('form.campaign.creations.acceptedFormats')}: `}
          <span className='CreationsFormUpload--title-bold'>
            {dropZoneOptions[mediaFormat as MediaFormat].acceptedFormats.join(', ')}
          </span>
        </div>
        <div>
          {`${t('form.campaign.creations.maxFileDimensions')}: `}
          <span className='CreationsFormUpload--title-bold'>{CREATIONS_MAX_FILE_DIMENSIONS} </span>
          {t('common.units.pixels')}
        </div>
        <div>
          {`${t('form.campaign.creations.maxFileSize')}: `}
          <span className='CreationsFormUpload--title-bold'>
            {VariableUtils.formatBytes(CREATIONS_MAX_FILE_SIZE)}
          </span>
        </div>
        <div>
          {`${t('form.campaign.creations.maxFiles')}: `}
          <span className='CreationsFormUpload--title-bold'>{CREATIONS_MAX_FILES}</span>
        </div>
        {!allowedFor({ template: CAMPAIGN_ACTION.UPLOAD_ZIP }) && (
          <div className='CreationsFormUpload__zip-info'>{`${t(
            'form.campaign.creations.zipInfo'
          )}`}</div>
        )}
      </div>
    </div>
  )

  return (
    <>
      {DropHeader}

      <ModalUpload
        onDrop={(uploadedFiles, setLoading) =>
          void service.handleSubmit(
            basicsValues.campaignId,
            basicsValues.name,
            creations,
            mediaFormat,
            uploadedFiles,
            setCreations,
            setLoading,
            setTriggerCreations,
            onFinish,
            userData.withAllocatedTime
          )
        }
      />
    </>
  )
}

export default CreationsFormUpload
