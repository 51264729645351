import api from 'api'
import {
  Alert,
  CampaignBudgetCurrency,
  CampaignStatus,
  CampaignTarget,
  Creation,
  MediaFormat,
} from 'types/campaign'
import { PublicViewProps } from '../types'
import EnumUtils from 'utils/enum'
import {
  CampaignPdfAudienceData,
  CreateCampaignSummaryPdfResponse,
  FetchPdfTaskStatusResponse,
  GetCampaignForDetailsData,
} from 'api/campaign/types'
import { Routes } from 'routes'
import DateUtils from 'utils/date'
import { DATE_FORMAT } from 'constant'
import { Dispatch, SetStateAction } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { ReactRouterParams } from 'types/various'
import { NotifierType } from '../../../../components/Notifier'
import { TFunction } from 'react-i18next'
import { CampaignData } from '../../models/campaign-data.model'
import { store } from '../../../../store'
import { campaignDetailsActions } from '../store/campaign-details-slice'
import { CampaignDetailsSliceModel } from '../../models/campaign-details-slice.model'

export default class CampaignDetailsService {
  static getCampaign = (
    campaignId: ReactRouterParams['id'],
    publicViewProps: PublicViewProps,
    navigate: NavigateFunction,
    setShouldReload: Dispatch<SetStateAction<boolean>>
  ): void => {
    const { campaignUuid, publicView } = publicViewProps

    if (publicView && campaignUuid) {
      api.campaign
        .getCampaignForDetailsPublic(campaignUuid)
        .then(
          res => void CampaignDetailsService.prepareCampaign(res.data.campaignReportPublic.campaign)
        )
        .catch(() => void navigate(Routes.SIGN.SIGN_IN))
        .finally(void setShouldReload(false))
    } else if (campaignId) {
      api.campaign
        .getCampaignForDetails(campaignId)
        .then(res => void CampaignDetailsService.prepareCampaign(res.data.node))
        .catch(() => void {})
        .finally(void setShouldReload(false))
    }
  }

  static prepareCampaign = (data: GetCampaignForDetailsData): void => {
    const {
      alerts,
      brainState,
      briefName,
      budget,
      budgetCurrency,
      creations,
      agency,
      endDate,
      id,
      impressions,
      emissionType,
      mediaCriteriaSearch,
      name,
      note,
      contractNote,
      offer,
      priority,
      reservationTill,
      startDate,
      status,
      supervisors,
      target,
      commercialAttribute,
      uuid,
      acceptanceStatus,
      estimatedValue,
      currentValue,
      createdAt,
      audience,
      mediaDuration,
      acceptanceAlerts,
      aggregatedAcceptanceAlerts,
      bundle,
    } = data

    store.dispatch(
      campaignDetailsActions.setCampaignData({
        alerts: alerts.filter((a: Alert) => a.active),
        brainState,
        acceptanceStatus,
        acceptanceAlerts,
        aggregatedAcceptanceAlerts,
        briefName,
        budget,
        commercialAttribute,
        budgetCurrency:
          CampaignBudgetCurrency[
            EnumUtils.getKeyByValue(
              CampaignBudgetCurrency,
              budgetCurrency
            ) as keyof typeof CampaignBudgetCurrency
          ],
        creations: creations.map((c: Creation) => ({
          ...c,
          mediaFormat: MediaFormat[c.mediaFormat as unknown as keyof typeof MediaFormat],
        })),
        agencyName: agency.name,
        agencyType: agency.agencyType,
        agencyWithAllocatedTime: agency.withAllocatedTime,
        billingAllowance: agency.billingAllowance,
        endDate,
        id,
        impressions: impressions,
        emissionType,
        mediaCriteriaSearch: {
          ...mediaCriteriaSearch,
          mediumFormat:
            MediaFormat[mediaCriteriaSearch.mediumFormat as unknown as keyof typeof MediaFormat],
        },
        name,
        note,
        contractNote,
        offer,
        priority,
        reservationTill: reservationTill ? new Date(reservationTill) : null,
        startDate,
        status:
          CampaignStatus[
            EnumUtils.getKeyByValue(CampaignStatus, status) as keyof typeof CampaignStatus
          ],
        supervisors,
        target:
          CampaignTarget[
            EnumUtils.getKeyByValue(CampaignTarget, target) as keyof typeof CampaignTarget
          ],
        uuid,
        currentValue,
        estimatedValue,
        createdAt,
        mediaDuration,
        audience,
        bundle,
      })
    )
  }

  static deleteCampaign = (
    campaignId: CampaignData['id'],
    navigate: NavigateFunction,
    addNotification: (type: NotifierType, text: string | string[], time?: number) => void,
    t: TFunction
  ): Promise<void> =>
    api.campaign.deleteCampaign(campaignId).then(res => {
      if (!res.data) {
        return
      }

      const { success } = res.data.destroyCampaign

      if (!success) {
        return
      }

      addNotification(NotifierType.SUCCESS, t('form.campaign.removal.success'))
      navigate(Routes.CAMPAIGNS.ALL)
    })

  static campaignTerm = (start: CampaignData['startDate'], end: CampaignData['endDate']): string =>
    `${DateUtils.parseAndFormat(start, DATE_FORMAT)} - ${DateUtils.parseAndFormat(
      end,
      DATE_FORMAT
    )}`

  static isDataForPdfFetched = (
    campaignData: CampaignDetailsSliceModel['campaignData'],
    campaignDetails: CampaignDetailsSliceModel['campaignDetails'],
    mediaLoading: boolean
  ): boolean =>
    campaignData !== undefined && campaignDetails !== undefined && mediaLoading === false

  static fetchCampaignPdfAudienceData = async (
    campaignId: CampaignData['id']
  ): Promise<CampaignPdfAudienceData | undefined> => {
    const res = await api.campaign.fetchCampaignPdfAudienceData(campaignId)
    return res.data?.campaignPdfAudienceData
  }

  static createCampaignSummaryPdf = async (
    uuid: CampaignData['uuid']
  ): Promise<CreateCampaignSummaryPdfResponse | undefined> => {
    const res = await api.campaign.createCampaignSummaryPdf(uuid)
    return res.data?.createCampaignSummaryPdf
  }

  static fetchPdfTaskStatus = async (
    jobId: string
  ): Promise<FetchPdfTaskStatusResponse | undefined> => {
    const res = await api.campaign.fetchPdfTaskStatus(jobId)
    return res.data?.task
  }
}
