import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext, FormCreation } from 'contexts/CampaignFormContext'
import TsxUtils from 'utils/tsx'
import StringUtils from 'utils/string'
import Button, { ButtonSize, ButtonTheme } from 'components/Form/Button'
import { faArchive, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import FlexRow from 'components/Layout/FlexRow'
import FlexCol from 'components/Layout/FlexCol'
import service from './service'
import Modal from 'components/Modal'
import CreationsFormUpload from '../CreationsFormUpload'
import { CreationsFormPreviewProps } from './types'
import Preview from 'components/Preview'
import Link from 'components/Link'
import { Routes } from 'routes'
import ModalAction from 'components/Modal/ModalAction'
import TooltipHelp from 'components/TooltipHelp'
import { AppContext } from 'contexts/AppContext'
import { CAMPAIGN_FORM } from 'constant/authorization'
import Input from 'components/Form/Input'
import CreationHelper from 'utils/creationHelper'
import './CreationsFormPreview.scss'
import {
  Campaign,
  CampaignAcceptanceStatus,
  CreationAcceptance,
  CreationAcceptanceDetails,
  MediaFormat,
} from '../../../../../types/campaign'
import { useDispatch, useSelector } from 'react-redux'
import {
  creationAcceptancesActions,
  selectAcceptancesForCreation,
} from '../store/creation-acceptances-slice'
import { CreationAcceptancesSliceModel } from '../models/creation-acceptances-slice.model'
import { RootState, store } from '../../../../../store'
import Tooltip from '../../../../../components/Tooltip'
import AcceptancesTable from '../../../../../components/CreationAcceptancesCard'
import { AppContextProps } from '../../../../../contexts/AppContext/types'
import { TFunction } from 'i18next'
import IconAlert from '../../../../../components/Icon/IconAlert'
import Slider from '../../../../../components/Form/Slider'
import { CREATION_DURATIONS, DATE_FORMAT } from '../../../../../constant'
import DateUtils from '../../../../../utils/date'
import CreationsEmissionsTooltipAlert from '../CreationEmissionsTooltipAlert/CreationsEmissionsTooltipAlert'
import { InputMask } from '../../../../../utils/inputMask'
import { CardStatus } from '../../../../../components/Layout/Card'
import Checkbox from '../../../../../components/Form/Checkbox'
import { AgencyType } from '../../../../../types/agency'

const CreationsFormPreview: React.FC<CreationsFormPreviewProps> = ({
  campaignData,
  creations,
  mediaFormat,
}) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const { allowedFor, userData } = useContext(AppContext)
  const { setTriggerCreations, setCreationsValues: setCreations } = useContext(CampaignFormContext)
  const [isAddCreationModalOpen, setIsAddCreationModalOpen] = useState<boolean>(false)

  const [editArchivedModalOpenFor, setEditArchivedModalOpenFor] = useState<
    FormCreation['id'] | null
  >(null)

  const [isPendingAcceptanceModalOpenFor, setIsPendingAcceptanceModalOpenFor] = useState<
    FormCreation['id'] | null
  >(null)

  const [icon, setIcon] = useState<string>()
  const [removeModalIsOpenFor, setRemoveModalIsOpenFor] = useState<FormCreation['id'] | null>(null)
  const [archiveModalIsOpenFor, setArchiveModalIsOpenFor] = useState<FormCreation['id'] | null>(
    null
  )
  const [deleteCreationLoading, setDeleteCreationLoading] = useState<boolean>(false)
  const [archiveCreationLoading, setArchiveCreationLoading] = useState<boolean>(false)
  const [hoverForAcceptation, setHoverForAcceptation] = useState<Campaign['id']>()
  const { detailsValues, basicsValues } = useSelector((state: RootState) => state.campaignForm.form)
  const creationsCardStatus = useSelector(
    (state: RootState) => state.campaignForm.cardStatus.creations
  )

  const setSendToAcceptance = useSelector(
    state =>
      (state as { creationAcceptances: CreationAcceptancesSliceModel }).creationAcceptances
        .setDisableSendToAcceptance
  )

  const loadingAcceptanceList = useSelector(
    state =>
      (state as { creationAcceptances: CreationAcceptancesSliceModel }).creationAcceptances
        .loadingAcceptanceList
  )

  const { addNotification } = useContext(AppContext)

  useEffect(() => {
    loadIcon(StringUtils.camelToKebabCase(mediaFormat))
  }, [mediaFormat])

  useEffect(() => {
    if (campaignData.id && detailsValues.mediumFormat === MediaFormat.DG_IN) {
      getAcceptancesList(campaignData.id)
    }
  }, [])

  const loadIcon = (icon: string): void => {
    import(`images/${icon}.svg`).then(image => void setIcon(image.default))
  }

  const addCreation = (): void => {
    setIsAddCreationModalOpen(true)
  }

  const getAcceptancesList = (campaignId: Campaign['id']): Promise<void> =>
    service.getAcceptancesList(campaignId)

  const deleteCreation = (creationId: FormCreation['id']): Promise<void> =>
    service.deleteCreation(creationId, creations, setCreations, setTriggerCreations)

  const isInternalAgency = basicsValues.agency?.rawData?.agencyType === AgencyType.internal

  const archiveCreation = (
    creationId: FormCreation['id'],
    addNotification: AppContextProps['addNotification'],
    t: TFunction,
    archiveReason?: string
  ): Promise<void> =>
    service.archiveCreation(
      creationId,
      setTriggerCreations,
      addNotification,
      t,
      archiveReason ?? ''
    )

  const sendToAcceptance = (campaignId: Campaign['id']): Promise<void> => {
    dispatch(creationAcceptancesActions.setDisableSendToAcceptance(true))
    return service.sendToAcceptance(campaignId, addNotification)
  }

  const campaignStatus = campaignData.status

  const isAnyCreation = creations.length > 0

  const showSendToAcceptancesBtn = () => {
    return (
      isAnyCreation &&
      detailsValues.mediumFormat === MediaFormat.DG_IN &&
      (!loadingAcceptanceList || setSendToAcceptance) &&
      allowedFor({
        template: CAMPAIGN_FORM.SEND_CREATIONS_TO_ACCEPTANCE,
        status: campaignStatus,
      }) &&
      detailsValues.acceptanceStatus !== CampaignAcceptanceStatus.pending_acceptation
    )
  }

  const creationContainer = (creation: FormCreation): ReactNode => {
    const acceptances: CreationAcceptance[] = selectAcceptancesForCreation(
      store.getState(),
      creation.id
    )

    let acceptedRequestsQuantity = 0

    let rejectedRequestsQuantity = 0

    const acceptancesDetails: CreationAcceptanceDetails[] = []

    acceptances.forEach(acceptance => {
      acceptancesDetails.push(...acceptance.acceptances)
      const acceptedAcceptances = acceptance.acceptances.filter(
        acceptanceDetails => acceptanceDetails.status === 'accepted'
      )
      const rejectedAcceptances = acceptance.acceptances.filter(
        acceptanceDetails => acceptanceDetails.status === 'rejected'
      )

      if (acceptedAcceptances.length === acceptance.acceptances.length) {
        acceptedRequestsQuantity += 1
      }

      if (rejectedAcceptances.length === acceptance.acceptances.length) {
        rejectedRequestsQuantity += 1
      }
    })

    const resolvedRequestsQuantity = acceptedRequestsQuantity + rejectedRequestsQuantity
    const pendingRequestsQuantity = acceptances.length - resolvedRequestsQuantity

    return (
      <div key={creation.id}>
        <FlexRow className='CreationsFormPreview__creation--row'>
          <FlexCol>
            {creation.archivedAt && (
              <TooltipHelp containerClassName='CreationsFormPreview__creation--tooltip'>
                {t('common.archivedInfo')}
              </TooltipHelp>
            )}
            <Preview
              className={
                'CreationsFormPreview__creation--preview' +
                TsxUtils.extraStyle(creation.archivedAt, 'CreationsFormPreview--grayed-out')
              }
              src={creation.url}
              thumbnailSrc={creation.thumbnailUrl}
              isVideo={creation.isVideo}
              alt={creation.filename}
            />
          </FlexCol>

          <FlexCol>
            <div
              className={
                'CreationsFormPreview__creation--name' +
                TsxUtils.extraStyle(creation.archivedAt, 'CreationsFormPreview--grayed-out')
              }
            >
              <div
                className={
                  'CreationsFormPreview__creation__text' +
                  TsxUtils.extraStyle(
                    !!(creation.alerts && creation.alerts.length),
                    'CreationsFormPreview__creation--alert'
                  )
                }
              >
                {creation.filename}
                {!!(creation.alerts && creation.alerts.length) && (
                  <TooltipHelp containerClassName='CreationAlert'>
                    <div className='AlertContent'>
                      <div className='AlertContent__icon'>
                        <IconAlert />
                      </div>
                      <div className='AlertContent__container'>
                        <div className='AlertContent__title'>{t('common.alert')}</div>
                        <div>
                          <p>
                            {t('form.campaign.creations.alertTop')} {creation.filename}.
                          </p>
                          <p className={'AlertContent__solution'}>
                            {t('form.campaign.creations.alertSolution')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </TooltipHelp>
                )}
              </div>
            </div>

            <div className='CreationsFormPreview__creation-details--container'>
              <p className='CreationsFormPreview__creation-details--date'>
                {CreationHelper.getCreationDateText(creation)}
              </p>
              {creation.schedules && (
                <div>
                  <TooltipHelp containerClassName='CreationSchedules'>
                    <div className='CreationSchedules__details'>
                      <div className='CreationSchedules__header'>
                        <p>{`${t('common.scheduleDates')}`}:</p>
                      </div>
                      <div>
                        {creation.schedules.map((schedule, index) => (
                          <p key={index}>
                            {`${DateUtils.parseAndFormat(
                              schedule.startDate,
                              DATE_FORMAT
                            )} - ${DateUtils.parseAndFormat(schedule.endDate, DATE_FORMAT)}`}
                          </p>
                        ))}
                      </div>
                    </div>
                  </TooltipHelp>
                </div>
              )}
            </div>

            {acceptancesDetails.length > 0 && (
              <>
                <Link
                  className='CreationsFormPreview__creation-details--pending-acceptance'
                  dataCy={'pending-acceptances-link'}
                  onClick={() => void setIsPendingAcceptanceModalOpenFor(creation.id)}
                  onMouseEnter={() => {
                    setHoverForAcceptation(creation.id)
                  }}
                  onMouseLeave={() => {
                    setHoverForAcceptation(undefined)
                  }}
                >
                  {t('form.campaign.creations.acceptances')} ({resolvedRequestsQuantity}/
                  {acceptances.length})
                </Link>

                {hoverForAcceptation?.includes(creation.id) && (
                  <div>
                    <Tooltip>
                      <FlexRow>
                        {t('form.campaign.creations.accepted')}: {acceptedRequestsQuantity}
                      </FlexRow>
                      <FlexRow>
                        {t('form.campaign.creations.rejected')}: {rejectedRequestsQuantity}
                      </FlexRow>
                      <FlexRow>
                        {t('form.campaign.creations.accepting')}: {pendingRequestsQuantity}
                      </FlexRow>
                    </Tooltip>
                  </div>
                )}
              </>
            )}

            <div className='CreationsFormPreview__creation--details'>
              {!userData.withAllocatedTime && (
                <Input
                  id='details'
                  title={t('common.creationDetails')}
                  disabled={setSendToAcceptance}
                  value={creation.details || ''}
                  onChange={value => {
                    const valueToUpdate = userData.withAllocatedTime ? basicsValues.name : value

                    void service.updateCreationDetails({
                      value: valueToUpdate,
                      creationId: creation.id,
                      creations,
                      setCreations,
                    })
                  }}
                />
              )}
              {campaignData.target === 'impressions' && (
                <div className={'CreationsFormPreview__target-container'}>
                  <Input
                    dataCy={'creation-emissions'}
                    className={`CreationsFormPreview__target`}
                    title={t('form.campaign.creations.creationEmissions')}
                    disabled={setSendToAcceptance}
                    value={creation.impressions?.toString() || ''}
                    maskType={InputMask.INTEGER}
                    onChange={value => {
                      void service.updateCreationEmissionsNumber({
                        value,
                        creationId: creation.id,
                        creations,
                        setCreations,
                      })
                    }}
                    errors={creationsCardStatus === CardStatus.ERROR ? [''] : []}
                  />
                  <CreationsEmissionsTooltipAlert
                    creations={creations}
                    currentCreationId={creation.id}
                    creationEmissionNumber={creation.impressions?.toString() || ''}
                    campaignEmissions={campaignData.impressions}
                  ></CreationsEmissionsTooltipAlert>
                </div>
              )}
              {campaignData.target === 'audience' && (
                <div className={'CreationsFormPreview__target-container'}>
                  <Input
                    dataCy={'creation-audience'}
                    className={`CreationsFormPreview__target`}
                    title={t('form.campaign.creations.creationAudience')}
                    disabled={setSendToAcceptance}
                    value={creation.audience?.toString() || ''}
                    maskType={InputMask.INTEGER}
                    onChange={value => {
                      void service.updateCreationAudienceNumber({
                        newAudience: value,
                        creationId: creation.id,
                        creations,
                        setCreations,
                      })
                    }}
                    errors={creationsCardStatus === CardStatus.ERROR ? [''] : []}
                  />
                  <CreationsEmissionsTooltipAlert
                    creations={creations}
                    currentCreationId={creation.id}
                    creationEmissionNumber={creation.audience?.toString() || ''}
                    campaignEmissions={campaignData.audience}
                  ></CreationsEmissionsTooltipAlert>
                </div>
              )}
            </div>
            <Slider
              className={`CreationsFormPreview__duration${creation.isVideo ? '--disabled' : ''}`}
              id='duration'
              title={t('form.campaign.creations.duration')}
              disabled={isInternalAgency}
              value={isInternalAgency ? detailsValues.mediaDuration : creation.duration}
              onChange={value => {
                !creation.isVideo && !isInternalAgency
                service.updateCreationDuration({
                  value,
                  creationId: creation.id,
                  creations,
                  setCreations,
                })
              }}
              marks={CREATION_DURATIONS}
              step={CREATION_DURATIONS[1] - CREATION_DURATIONS[0]}
            />
            <div className='CreationsFormPreview__contains-alcohol'>
              <Checkbox
                id={creation.id}
                checked={creation.containsAlcohol}
                onChange={(event): void => {
                  service.updateCreationContainsAlcohol({
                    value: event.target.checked,
                    creationId: creation.id,
                    creations,
                    setCreations,
                  })
                }}
              >
                {t('form.campaign.creations.containsAlcohol')}
              </Checkbox>
            </div>
            <div className='CreationsFormPreview__creation-details--container'>
              <Link
                disabled={setSendToAcceptance}
                className='CreationsFormPreview__creation-details'
                toUrl={Routes.CAMPAIGNS.EMISSION_SCHEDULE({
                  campaignId: campaignData.id,
                  creationId: creation.id,
                  readOnly: '',
                })}
              >
                {t('form.campaign.creations.editEmission')}
              </Link>
              {creation.archivedAt && (
                <Link
                  className='CreationsFormPreview__creation-details--archived'
                  onClick={() => void setEditArchivedModalOpenFor(creation.id)}
                >
                  {t('common.archived')}
                </Link>
              )}
            </div>
          </FlexCol>

          <FlexCol>
            {allowedFor({
              template: CAMPAIGN_FORM.REMOVE_CREATION_BY_CAMPAIGN_STATUS,
              status: campaignStatus,
            }) &&
              allowedFor({
                template: CAMPAIGN_FORM.REMOVE_CREATION_BY_CREATION_STATUS,
                status: creation.state,
              }) && (
                <Button
                  icon={faTrashAlt}
                  size={ButtonSize.SMALL}
                  theme={ButtonTheme.NAVY_BLUE}
                  disabled={setSendToAcceptance}
                  tooltip={t('common.remove')}
                  onClick={() => void setRemoveModalIsOpenFor(creation.id)}
                />
              )}

            {allowedFor({
              template: CAMPAIGN_FORM.ARCHIVE_CREATION_BY_CAMPAIGN_STATUS,
              status: campaignStatus,
            }) &&
              allowedFor({
                template: CAMPAIGN_FORM.ARCHIVE_CREATION_BY_CREATION_STATUS,
                status: creation.state,
              }) &&
              !creation.archivedAt && (
                <>
                  <Button
                    dataCy={'archive-creation-btn'}
                    icon={faArchive}
                    size={ButtonSize.SMALL}
                    theme={ButtonTheme.NAVY_BLUE}
                    tooltip={t('common.archive')}
                    onClick={() => void setArchiveModalIsOpenFor(creation.id)}
                  />
                  <TooltipHelp containerClassName='CreationsFormPreview__button--tooltip'>
                    {t('form.campaign.creations.archiveButtonInfo')}
                  </TooltipHelp>
                </>
              )}
          </FlexCol>
        </FlexRow>

        {removeModalIsOpenFor?.includes(creation.id) && (
          <ModalAction
            title={t('modal.creationRemoval')}
            itemText={t('modal.doYouWantToRemove')}
            itemName={creation.filename}
            actionText={t('common.remove')}
            isOpen={!!removeModalIsOpenFor}
            setIsOpen={() => void setRemoveModalIsOpenFor(null)}
            onAction={() => deleteCreation(creation.id)}
            isLoading={deleteCreationLoading}
            setIsLoading={setDeleteCreationLoading}
          />
        )}

        {archiveModalIsOpenFor?.includes(creation.id) && (
          <ModalAction
            actionText={t('modal.archive')}
            isLoading={archiveCreationLoading}
            isOpen={!!archiveModalIsOpenFor}
            itemName={creation.filename}
            itemText={t('modal.doYouWantToArchive')}
            onAction={(reason?: string) => archiveCreation(creation.id, addNotification, t, reason)}
            setIsLoading={setArchiveCreationLoading}
            setIsOpen={() => void setArchiveModalIsOpenFor(null)}
            textInputHeader={t('common.reason')}
            textInputInitValue={creation.archiveReason}
            title={t('modal.creationArchiving')}
          />
        )}

        {editArchivedModalOpenFor?.includes(creation.id) && (
          <ModalAction
            actionText={t('modal.archive')}
            isLoading={archiveCreationLoading}
            isOpen={!!editArchivedModalOpenFor}
            onAction={(reason?: string) => archiveCreation(creation.id, addNotification, t, reason)}
            setIsLoading={setArchiveCreationLoading}
            setIsOpen={() => void setEditArchivedModalOpenFor(null)}
            textInputHeader={t('common.reason')}
            textInputInitValue={creation.archiveReason}
            title={t('modal.archivedCreation') + ` ${creation.filename}`}
          />
        )}

        {isPendingAcceptanceModalOpenFor?.includes(creation.id) && (
          <Modal
            isOpen={!!isPendingAcceptanceModalOpenFor}
            classNameChildren={'AcceptancesListModal'}
            onClose={() => void setIsPendingAcceptanceModalOpenFor(null)}
            classNameContent={'Modal__content--acceptances-list'}
          >
            <AcceptancesTable
              acceptances={acceptances}
              loadingAcceptances={loadingAcceptanceList}
              border={false}
            ></AcceptancesTable>
          </Modal>
        )}
      </div>
    )
  }

  return (
    <div className='CreationsFormPreview'>
      <div className='CreationsFormPreview__bar'>
        <img
          className='CreationsFormPreview__bar--icon'
          src={icon}
          alt={mediaFormat}
        />
        <div className='CreationsFormPreview__bar--title'>{t(`mediaType.${mediaFormat}`)}</div>
        {allowedFor({ template: CAMPAIGN_FORM.ADD_CREATION, status: campaignStatus }) && (
          <Button
            className='CreationsFormPreview__bar--add'
            dataCy={'add-creation-btn'}
            icon={faPlus}
            size={ButtonSize.SMALL}
            tooltip={t('form.campaign.creations.addCreation')}
            onClick={addCreation}
          />
        )}
      </div>

      <div
        className={
          'CreationsFormPreview__creation' +
          TsxUtils.extraStyle(!isAnyCreation, 'CreationsFormPreview__creation--empty')
        }
      >
        {isAnyCreation
          ? creations.map((c: FormCreation) => creationContainer(c))
          : t('form.campaign.creations.noCreation')}
      </div>

      {isAddCreationModalOpen && (
        <Modal
          title={t('form.campaign.creations.addingCreations')}
          isOpen={isAddCreationModalOpen}
          onClose={(isOpen: boolean) => void setIsAddCreationModalOpen(isOpen)}
          classNameContent={'Modal__content--creations-form-upload'}
          footer={
            <Button
              theme={ButtonTheme.BLUE_OUTLINE}
              onClick={() => void setIsAddCreationModalOpen(false)}
            >
              {t('common.cancel')}
            </Button>
          }
        >
          <CreationsFormUpload
            key={mediaFormat}
            mediaFormat={mediaFormat}
            onFinish={() => void setIsAddCreationModalOpen(false)}
          />
        </Modal>
      )}

      {showSendToAcceptancesBtn() && (
        <Button
          className={'CreationsFormPreview__button--send-to-acceptance'}
          dataCy={'send-creation-to-acceptance-btn'}
          theme={ButtonTheme.BLUE}
          disabled={setSendToAcceptance}
          onClick={() => {
            sendToAcceptance(campaignData.id)
          }}
        >
          {t('form.campaign.creations.sendToAcceptance')}
        </Button>
      )}
    </div>
  )
}

export default CreationsFormPreview
