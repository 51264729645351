import { AppMode } from './env'
import DateUtils from './date'
import { CampaignBundle } from '../types/campaign'

export class BillingsHelper {
  /**
   * Function which should tell if billings should be shown
   *
   * @param createdAt
   * @param bundle
   * @param userWithAllocatedTime - is true when user owns a media, so when he owns a media
   * @param campaignAgencyWithAllocatedTime
   */
  static show(
    createdAt?: string,
    bundle?: CampaignBundle | null,
    userWithAllocatedTime?: boolean,
    campaignAgencyWithAllocatedTime?: boolean
  ): boolean {
    /**
     * If campaign is not created yet, we should not show billings
     */
    if (!createdAt) {
      return false
    }

    /**
     * If campaign is a bundle, we should not show billings
     */
    if (bundle) {
      return false
    }

    /**
     * If we are in production, we should not show billings if they are created before 24.04.2024
     */
    if (
      process.env?.REACT_APP_CUSTOM_NODE_ENV === AppMode.PRODUCTION &&
      DateUtils.isBeforeDay(new Date(createdAt), new Date('2024-04-24'))
    ) {
      return false
    }

    /**
     * If we are on any other environment that production, we should not show billings if they are created before 19.08.2024
     */
    if (
      process.env?.REACT_APP_CUSTOM_NODE_ENV !== AppMode.PRODUCTION &&
      DateUtils.isBeforeDay(new Date(createdAt), new Date('2024-04-18'))
    ) {
      return false
    }

    /**
     * If user owns a media, he should not see billings
     */
    if (userWithAllocatedTime) {
      return false
    }

    if (campaignAgencyWithAllocatedTime) {
      return false
    }

    /**
     * For other cases like:
     * - no agency (new campaign)
     * - agency is internal
     * - agency is external
     * - billing allowance is false
     * - admins, clients, employees etc
     *
     * we should show billings
     */
    return true
  }
}
