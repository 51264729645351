import { Agency, AgencyType } from 'types/agency'
import { AgencyOrigin, Currency } from 'api/agency/types'
import { UseNotifierProps } from 'hooks/useNotifier'
import { User } from 'types/user'
import { TFunction } from 'i18next'
import { NavigateFunction } from 'react-router-dom'
import { SelectAsyncValue } from 'components/Form/SelectAsync/types'

export type AgencyForm = Pick<
  Agency,
  | 'id'
  | 'clientId'
  | 'name'
  | 'company'
  | 'agencyType'
  | 'origin'
  | 'currencyType'
  | 'discount'
  | 'billingAllowance'
  | 'allocatedTime'
  | 'withAllocatedTime'
  | 'agencyMediums'
  | 'mediumAllocatedTime'
  | 'allowedTargets'
  | 'invoiceEmail'
> & {
  supervisors: SelectAsyncValue<User>[]
}

export interface SubmitArgs {
  agency: AgencyForm
  navigate: NavigateFunction
  addNotification: UseNotifierProps['addNotification']
  setLoadingAgency: React.Dispatch<React.SetStateAction<boolean>>
  t: TFunction
}

export const agencyDefault: AgencyForm = {
  id: '',
  clientId: '',
  name: '',
  company: null,
  origin: AgencyOrigin.DOMESTIC,
  agencyType: AgencyType.external,
  currencyType: Currency.PLN,
  invoiceEmail: '',
  supervisors: [],
  discount: 0,
  billingAllowance: false,
  allocatedTime: 0,
  withAllocatedTime: false,
  agencyMediums: [],
  mediumAllocatedTime: 0,
  allowedTargets: [],
}
