import EnumUtils from 'utils/enum'
import api from 'api'
import { FormCreation, CreationsValues } from 'contexts/CampaignFormContext'
import { CreationFile, CreationStatus, MediaFormat } from 'types/campaign'
import { CardStatus } from 'components/Layout/Card/types'
import CreationThumbnailVertical from 'images/creation-thumbnail-vertical.svg'
import CreationThumbnailHorizontal from 'images/creation-thumbnail-horizontal.svg'
import CreationThumbnailZip from 'images/creation-thumbnail-zip.svg'
import { ModalUploadFile } from 'components/Modal/ModalUpload'
import { store } from '../../../../../store'
import { creationAcceptancesActions } from '../store/creation-acceptances-slice'
import { ALCOHOL_THEME_DEFAULT, DEFAULT_CREATION_DURATION } from '../../../../../constant'
import { campaignActions } from '../../../store/campaign-form-slice'
import CampaignFormContextService from '../../../../../contexts/CampaignFormContext/service'

export default class CreationsFormUploadService {
  static handleSubmit = (
    campaignId: string,
    campaignName: string,
    creations: CreationsValues,
    mediaFormat: MediaFormat,
    uploadedFiles: ModalUploadFile[],
    setCreations: React.Dispatch<React.SetStateAction<CreationsValues>>,
    setLoading: (isLoading: boolean) => void,
    setTriggerCreations: React.Dispatch<React.SetStateAction<boolean>>,
    onFinish: () => void,
    withAllocatedTime: boolean
  ): void => {
    setLoading(true)
    store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVING }))

    api.creation
      .createCreations({
        campaignId,
        mediaFormat: EnumUtils.getKeyByValue(MediaFormat, mediaFormat),
        files: uploadedFiles,
        duration: DEFAULT_CREATION_DURATION,
        containsAlcohol: ALCOHOL_THEME_DEFAULT,
      })
      .then(res => {
        if (!res.data) {
          return
        }

        const newCreations: FormCreation[] = res.data.createCreations.creations.map(creation => {
          const {
            id,
            archiveReason,
            details,
            file: { url, filename, isVideo, mimeType },
            state,
            emissionStartDate,
            alerts,
            createdAt,
            schedules,
            impressions,
            containsAlcohol,
            audience,
          } = creation

          return {
            id,
            archiveReason: archiveReason,
            details: withAllocatedTime ? campaignName : details,
            mediaFormat,
            url,
            filename,
            isVideo,
            thumbnailUrl: CreationsFormUploadService.generateThumbnailURL(
              isVideo,
              mimeType,
              mediaFormat,
              CreationsFormUploadService.findUploadedFileByName(uploadedFiles, filename)!.preview
            ),
            approved: false,
            state: CreationStatus[EnumUtils.getKeyByValueT(CreationStatus, state)!],
            emissionStartDate,
            alerts,
            duration: 0,
            createdAt,
            schedules,
            impressions,
            containsAlcohol,
            audience,
          }
        })

        setCreations([...creations, ...newCreations])
        store.dispatch(creationAcceptancesActions.setDisableSendToAcceptance(false))
        store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVED }))

        if (withAllocatedTime) {
          CampaignFormContextService.updateCreationDetails(newCreations[0])
        }
      })
      .catch(
        () => void store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.ERROR }))
      )
      .finally(() => {
        setLoading(false)
        setTriggerCreations(true)
        onFinish()
      })
  }

  static findUploadedFileByName = (
    uploadedFiles: ModalUploadFile[],
    searchedName: ModalUploadFile['name']
  ): ModalUploadFile | undefined =>
    uploadedFiles.find((f: ModalUploadFile) => f.name === searchedName)

  static generateThumbnailURL = (
    isVideo: CreationFile['isVideo'],
    mimeType: CreationFile['mimeType'],
    mediaFormat: MediaFormat,
    filePreview: ModalUploadFile['preview']
  ): FormCreation['thumbnailUrl'] => {
    let thumbnailUrl
    if (isVideo) {
      if (mediaFormat === MediaFormat.DG_IN) {
        thumbnailUrl = CreationThumbnailVertical
      } else {
        thumbnailUrl = CreationThumbnailHorizontal
      }
    } else if (mimeType === 'application/zip') {
      thumbnailUrl = CreationThumbnailZip
    } else {
      thumbnailUrl = filePreview
    }

    return thumbnailUrl
  }
}
