import api from 'api'
import { FormCreation } from 'contexts/CampaignFormContext'
import { CardStatus } from 'components/Layout/Card'
import { store } from '../../store'
import { campaignActions } from '../../pages/Campaign/store/campaign-form-slice'

export default class CampaignFormContextService {
  static updateCreationDetails = (creation: FormCreation): Promise<void> | void => {
    const { id: creationId, details } = creation
    if (!details) {
      return
    }

    store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVING }))
    return api.creation
      .updateCreationDetails({ creationId, details })
      .then(data => {
        void store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVED }))
        if (data && data.data?.updateCreationDetails.brainState) {
          const brainState = data.data.updateCreationDetails.brainState
          if (brainState) store.dispatch(campaignActions.updateBasicsValues({ brainState }))
        }
      })
      .catch(
        () => void store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.ERROR }))
      )
  }

  static updateCreationDuration = (creation: FormCreation): Promise<void> | void => {
    const { id: creationId, duration } = creation

    store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVING }))
    return api.creation
      .updateCreationDuration({ creationId, duration })
      .then(data => {
        void store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVED }))
        if (data && data.data?.updateCreationDuration.brainState) {
          const brainState = data.data.updateCreationDuration.brainState
          if (brainState) store.dispatch(campaignActions.updateBasicsValues({ brainState }))
        }
      })
      .catch(
        () => void store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.ERROR }))
      )
  }

  static updateCreationEmissionsNumber = (creation: FormCreation): Promise<void> | void => {
    const { id: creationId, impressions } = creation

    store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVING }))
    return api.creation
      .updateCreationEmissionsNumber(creationId, impressions)
      .then(data => {
        void store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVED }))
        if (data && data.data?.updateCreationImpressions.brainState) {
          const brainState = data.data.updateCreationImpressions.brainState
          if (brainState) store.dispatch(campaignActions.updateBasicsValues({ brainState }))
        }
      })
      .catch(
        () => void store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.ERROR }))
      )
  }

  static updateCreationAudienceNumber = (creation: FormCreation): Promise<void> | void => {
    const { id: creationId, audience } = creation

    store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVING }))
    return api.creation
      .updateCreationAudienceNumber(creationId, audience)
      .then(data => {
        void store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVED }))
        if (data && data.data?.updateCreationAudience.brainState) {
          const brainState = data.data.updateCreationAudience.brainState
          if (brainState) store.dispatch(campaignActions.updateBasicsValues({ brainState }))
        }
      })
      .catch(
        () => void store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.ERROR }))
      )
  }

  static updateCreationContainsAlcohol = (creation: FormCreation): Promise<void> | void => {
    const { id: creationId, containsAlcohol } = creation

    store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVING }))
    return api.creation
      .updateCreationContainsAlcohol({ creationId, containsAlcohol })
      .then(data => {
        void store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.SAVED }))
        if (data && data.data?.updateCreationContainsAlcohol.brainState) {
          const brainState = data.data.updateCreationContainsAlcohol.brainState
          if (brainState) store.dispatch(campaignActions.updateBasicsValues({ brainState }))
        }
      })
      .catch(() => {
        void store.dispatch(campaignActions.setCardStatus({ creations: CardStatus.ERROR }))
      })
  }
}
