import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CampaignData } from '../../models/campaign-data.model'
import { CampaignDetails } from '../../../../types/campaign'
import { CampaignDetailsSliceModel } from '../../models/campaign-details-slice.model'
import { produce } from 'immer'
import { AgeGroupDefaults, SexDefaults } from '../CampaignDetailsChart/types'

const defaultState: CampaignDetailsSliceModel = {
  displayedMediaLoaded: false,
  displayedMedia: [],
  filters: {
    available: {
      agglomerations: [],
      cities: [],
      buildings: [],
      mediaAsIds: [],
      pois: [],
      creations: [],
    },
    selected: {
      agglomerations: [],
      cities: [],
      buildings: [],
      mediaAsIds: [],
      pois: [],
      creations: [],
    },
  },
  audienceFilters: {
    available: {
      sex: [...SexDefaults],
      ageGroups: [...AgeGroupDefaults],
    },
    selected: {
      sex: [...SexDefaults],
      ageGroups: [...AgeGroupDefaults],
    },
  },
  chart: {
    loading: false,
    emissions: [],
  },
  campaignData: undefined,
  campaignDetails: undefined,
}

const initialState: CampaignDetailsSliceModel = produce(defaultState, draft => draft)

const campaignDetailsSlice = createSlice({
  name: 'campaignDetails',
  initialState,
  reducers: {
    setCampaignData(state, action: PayloadAction<CampaignData>) {
      state.campaignData = action.payload
    },
    setCampaignDetails(state, action: PayloadAction<CampaignDetails>) {
      state.campaignDetails = action.payload
    },
    resetCampaignDetails() {
      return defaultState
    },
    setChartLoading(state, action: PayloadAction<boolean>) {
      state.chart.loading = action.payload
    },
    setChartEmissions(
      state,
      action: PayloadAction<CampaignDetailsSliceModel['chart']['emissions']>
    ) {
      state.chart.emissions = action.payload
    },
    setDisplayedMedia(state, action: PayloadAction<CampaignDetailsSliceModel['displayedMedia']>) {
      state.displayedMedia = action.payload
    },
    setDisplayedMediaLoaded(state, action: PayloadAction<boolean>) {
      state.displayedMediaLoaded = action.payload
    },
    setAvailableFilters(
      state,
      action: PayloadAction<CampaignDetailsSliceModel['filters']['available']>
    ) {
      state.filters.available = action.payload
    },
    setSelectedFilters(
      state,
      action: PayloadAction<CampaignDetailsSliceModel['filters']['selected']>
    ) {
      state.filters.selected = action.payload
    },
    setSelectedAudienceFilters(
      state,
      action: PayloadAction<CampaignDetailsSliceModel['audienceFilters']['selected']>
    ) {
      state.audienceFilters.selected = action.payload
    },
  },
})

export const campaignDetailsActions = campaignDetailsSlice.actions

export default campaignDetailsSlice
