import { useState } from 'react'
import { MediaFormat, Medium, MediumPlacement, MediumStatus } from 'types/campaign'
import api from 'api'
import EnumUtils from 'utils/enum'
import {
  FetchCampaignMediaProps,
  FetchCreationMediaProps,
  FetchFilteredMediaResult,
  UseFilteredMediaReturn,
} from './types'

export const useFilteredMedia = (): UseFilteredMediaReturn => {
  const [mediaLoading, setMediaLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [media, setMedia] = useState<Medium[]>([])

  const fetchCreationMedia: UseFilteredMediaReturn['fetchCreationMedia'] = ({
    creationId,
    creationUuid,
  }): void => {
    setMediaLoading(true)

    fetchCreationMediaHelper({ creationId, creationUuid })
      .then(filteredMedia => parseFilteredMedia(filteredMedia))
      .catch(() => void {})
      .finally(() => void setMediaLoading(false))
  }

  const fetchCreationMediaHelper = ({
    creationId,
    creationUuid,
  }: FetchCreationMediaProps): Promise<FetchFilteredMediaResult> => {
    if (creationUuid) {
      return api.media
        .getCreationMediaPublic({
          creationUuid,
        })
        .then(res => res.data.creationMediaPublic)
    }

    return api.media
      .getCreationMedia({ creationId: creationId! })
      .then(res => res.data.creationMedia)
  }

  const fetchCampaignMedia: UseFilteredMediaReturn['fetchCampaignMedia'] = ({
    campaignId,
    campaignUuid,
    first,
    last,
    editCampaign,
  }): void => {
    setMediaLoading(true)

    fetchCampaignMediaHelper({ campaignId, campaignUuid, first, last, editCampaign })
      .then(filteredMedia => {
        parseFilteredMedia(filteredMedia)
      })
      .catch(() => void {})
      .finally(() => void setMediaLoading(false))
  }

  const fetchCampaignMediaHelper = ({
    campaignId,
    campaignUuid,
    first,
    last,
    editCampaign,
  }: FetchCampaignMediaProps): Promise<FetchFilteredMediaResult> => {
    if (campaignUuid) {
      return api.media
        .getFilteredMediaPublic({ campaignUuid, first, last })
        .then(res => res.data.campaignReportPublic.filteredMedia)
    }

    return api.media
      .getFilteredMedia({ campaignId, first, last, editCampaign })
      .then(res => res.data.filteredMedia)
  }

  const parseFilteredMedia = ({ totalCount, nodes }: FetchFilteredMediaResult) => {
    const parsedMedia: Medium[] = nodes.map(medium => {
      const { pois, ...rest } = medium
      const poiCategories =
        pois && pois.edges && pois.edges.length
          ? [...new Set(pois.edges.map(poi => poi.node.category))]
          : []

      return {
        ...rest,
        mediaFormat: MediaFormat[medium.mediaFormat as string as keyof typeof MediaFormat],
        placement:
          MediumPlacement[
            EnumUtils.getKeyByValue(
              MediumPlacement,
              medium.placement
            ) as keyof typeof MediumPlacement
          ],
        status: MediumStatus[EnumUtils.getKeyByValueT(MediumStatus, medium.status)!],
        poiCategories,
      }
    })

    setTotalCount(totalCount)
    setMedia(parsedMedia)
  }

  return {
    fetchCreationMedia,
    fetchCampaignMedia,
    media,
    mediaLoading,
    page,
    setPage,
    totalCount,
  }
}
