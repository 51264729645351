import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CampaignDetailsChartProps } from '../CampaignDetailsChart/types'
import FlexRow from 'components/Layout/FlexRow'
import { AppContext } from 'contexts/AppContext'
import './CampaignDetailsChartFilters.scss'
import { CampaignTarget, MediaFormat } from '../../../../types/campaign'
import { SelectOption } from '../../../../components/Form/Select'
import SelectMultiCheckbox from '../../../../components/Form/SelectMultiCheckbox'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { campaignDetailsActions } from '../store/campaign-details-slice'
import { CampaignDetailsSliceModel } from '../../models/campaign-details-slice.model'
import ChartFiltersSupportService from './chart-filters-support.service'
import { Features } from '../../../../constant/features'

const CampaignDetailsChartFilters: React.FC<CampaignDetailsChartProps> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isFeatureActive } = useContext(AppContext)
  const { displayedMedia, campaignData, filters, audienceFilters, displayedMediaLoaded, chart } =
    useSelector((state: RootState) => state.campaignDetails)
  const isInDoor =
    campaignData?.mediaCriteriaSearch.mediumFormat === MediaFormat.DG_IN ||
    campaignData?.mediaCriteriaSearch.mediumFormat === MediaFormat.DG_IN_H ||
    campaignData?.mediaCriteriaSearch.mediumFormat === MediaFormat.DG_IN_S
  const isAudienceCampaign = campaignData?.target === CampaignTarget.AUDIENCE

  const valueToSelectOption = (value: string): SelectOption => ({ value, label: value })

  useEffect(() => {
    const filters: CampaignDetailsSliceModel['filters']['available'] =
      ChartFiltersSupportService.getAllFiltersByDisplayedMediaAndCampaign(
        displayedMedia,
        campaignData
      )

    dispatch(campaignDetailsActions.setAvailableFilters(filters))
    dispatch(campaignDetailsActions.setSelectedFilters(filters))
  }, [displayedMedia])

  return (
    <FlexRow className='CampaignDetailsChartFilters'>
      <div className={'CampaignDetailsChartFilters__row'}>
        <SelectMultiCheckbox
          id='agglomerations'
          className='FilterSelect'
          disabled={chart.loading || !displayedMediaLoaded}
          closeOnSelect={false}
          onChange={(selected): void => {
            const availableFilters = ChartFiltersSupportService.filterByAgglomerations(
              selected,
              filters.available,
              displayedMedia
            )
            const selectedFilters = selected.length
              ? { ...availableFilters, agglomerations: selected }
              : {
                  ...filters.selected,
                  agglomerations: selected,
                  cities: [],
                  buildings: [],
                  mediaAsIds: [],
                }
            void dispatch(campaignDetailsActions.setAvailableFilters(availableFilters))
            void dispatch(campaignDetailsActions.setSelectedFilters(selectedFilters))
          }}
          options={filters.available.agglomerations.map(valueToSelectOption)}
          selectAllOption
          title={t('common.agglomeration')}
          value={filters.selected.agglomerations}
        />

        <SelectMultiCheckbox
          id='cities'
          className='FilterSelect'
          disabled={chart.loading || !displayedMediaLoaded}
          closeOnSelect={false}
          onChange={(selected): void => {
            const availableFilters = ChartFiltersSupportService.filterByCities(
              selected,
              filters.available,
              displayedMedia
            )
            const selectedFilters = selected.length
              ? { ...availableFilters, cities: selected }
              : {
                  ...filters.selected,
                  cities: selected,
                  buildings: [],
                  mediaAsIds: [],
                }
            void dispatch(campaignDetailsActions.setAvailableFilters(availableFilters))
            void dispatch(campaignDetailsActions.setSelectedFilters(selectedFilters))
          }}
          options={filters.available.cities.map(valueToSelectOption)}
          selectAllOption
          title={t('common.city')}
          value={filters.selected.cities}
        />

        <SelectMultiCheckbox
          id='creations'
          className='FilterSelect'
          disabled={chart.loading || !displayedMediaLoaded}
          closeOnSelect={false}
          onChange={(selected): void => {
            const selectedFilters = {
              ...filters.selected,
              creations: selected,
            }
            void dispatch(campaignDetailsActions.setSelectedFilters(selectedFilters))
          }}
          options={
            campaignData
              ? filters.available.creations.map((creationId: string) => {
                  const creation = campaignData.creations?.filter(
                    creation => creation.id === creationId
                  )[0]
                  return {
                    value: creationId,
                    label: creation?.file.filename,
                  }
                })
              : []
          }
          selectAllOption
          title={t('campaign.creation')}
          value={filters.selected.creations}
        />
      </div>

      <div className={'CampaignDetailsChartFilters__row'}>
        {isInDoor && (
          <SelectMultiCheckbox
            id='buildings'
            className='FilterSelect'
            disabled={chart.loading || !displayedMediaLoaded}
            closeOnSelect={false}
            onChange={(selected): void => {
              const availableFilters = ChartFiltersSupportService.filterByBuildings(
                selected,
                filters.available,
                displayedMedia
              )
              const selectedFilters = selected.length
                ? { ...availableFilters, buildings: selected }
                : {
                    ...filters.selected,
                    buildings: selected,
                    mediaAsIds: [],
                  }
              void dispatch(campaignDetailsActions.setAvailableFilters(availableFilters))
              void dispatch(campaignDetailsActions.setSelectedFilters(selectedFilters))
            }}
            options={filters.available.buildings.map(valueToSelectOption)}
            selectAllOption
            title={t('common.building')}
            value={filters.selected.buildings}
          />
        )}

        <SelectMultiCheckbox
          id='mediaAsIds'
          className='FilterSelect'
          disabled={chart.loading || !displayedMediaLoaded}
          closeOnSelect={false}
          onChange={(selected): void => {
            const availableFilters = ChartFiltersSupportService.filterByMediaAsIds(
              selected,
              filters.available,
              displayedMedia
            )
            const selectedFilters = { ...availableFilters, mediaAsIds: selected }
            void dispatch(campaignDetailsActions.setAvailableFilters(availableFilters))
            void dispatch(campaignDetailsActions.setSelectedFilters(selectedFilters))
          }}
          options={filters.available.mediaAsIds.map(valueToSelectOption)}
          selectAllOption
          title={t('common.medium')}
          value={filters.selected.mediaAsIds}
        />
      </div>

      {isFeatureActive(Features.POI) && (
        <SelectMultiCheckbox
          id='pois'
          className='FilterSelect'
          disabled={chart.loading || !displayedMediaLoaded}
          closeOnSelect={false}
          onChange={(selected): void => {
            void dispatch(
              campaignDetailsActions.setSelectedFilters({ ...filters.selected, pois: selected })
            )
          }}
          options={filters.available.mediaAsIds.map(valueToSelectOption)}
          selectAllOption
          title={t('common.pois')}
          value={filters.selected.mediaAsIds}
        />
      )}

      {isInDoor && isAudienceCampaign && (
        <>
          <SelectMultiCheckbox
            id='sex'
            title={t('common.sex')}
            disabled={chart.loading || !displayedMediaLoaded}
            value={audienceFilters.selected.sex.map(String)}
            options={audienceFilters.available.sex.map(
              (value: number): SelectOption => ({
                value: value.toString(),
                label: t(`sexFilters.${value}`),
              })
            )}
            onChange={(selected): void => {
              const parsedValues = selected.map(value => parseInt(value))
              void dispatch(
                campaignDetailsActions.setSelectedAudienceFilters({
                  ...audienceFilters.selected,
                  sex: [...parsedValues],
                })
              )
            }}
            selectAllOption
          />

          <SelectMultiCheckbox
            id='ageGroups'
            title={t('common.ageGroup')}
            disabled={chart.loading || !displayedMediaLoaded}
            value={audienceFilters.selected.ageGroups.map(String)}
            options={audienceFilters.available.ageGroups.map(
              (value: number): SelectOption => ({
                value: value.toString(),
                label: t(`ageGroupFilters.${value}`),
              })
            )}
            onChange={(selected): void => {
              const parsedValues = selected.map(value => parseInt(value))
              void dispatch(
                campaignDetailsActions.setSelectedAudienceFilters({
                  ...audienceFilters.selected,
                  ageGroups: [...parsedValues],
                })
              )
            }}
            selectAllOption
          />
        </>
      )}
    </FlexRow>
  )
}

export default CampaignDetailsChartFilters
