import React from 'react'
import { useTranslation } from 'react-i18next'
import './InventoryDetailsModal.scss'
import Modal from '../../../components/Modal'
import { InventoryBuildingModel } from '../models/inventory-buiding.model'

const InventoryDetailsModal: React.FC<{
  inventoryData: InventoryBuildingModel[]
  closeClick: (open: boolean) => void
}> = ({ inventoryData, closeClick }) => {
  const { t } = useTranslation()

  const getDisplayedPercentage = (occupiedPercentage: number): string => {
    return `${Math.round(occupiedPercentage * 100)}%`
  }

  return (
    <Modal
      title={`${t('inventory.galleryOccupancy.mediaOccupancy')} / ${
        inventoryData[0]?.buildingName
      }`}
      classNameOverlay='InventoryDetailsModal'
      isOpen={inventoryData.length > 0}
      onClose={(isOpen: boolean) => void closeClick(isOpen)}
    >
      {inventoryData.length > 0 && (
        <>
          <div className='InventoryDetailsModal__agglomeration'>
            <p className='InventoryDetailsModal__common-text InventoryDetailsModal__common-text--bold'>
              {t('common.asId')}
            </p>
            <p className='InventoryDetailsModal__common-text InventoryDetailsModal__common-text--bold'>
              {t('inventory.galleryOccupancyDetails.percentageOccupancy')}
            </p>
            <p className='InventoryDetailsModal__common-text InventoryDetailsModal__common-text--bold'></p>
            <p className='InventoryDetailsModal__common-text InventoryDetailsModal__common-text--bold'>
              {t('inventory.galleryOccupancyDetails.occupiedToTotal')}
            </p>
          </div>
          {inventoryData.map((inventory, index) => (
            <div
              className='InventoryDetailsModal__agglomeration'
              key={index}
            >
              <p className='InventoryDetailsModal__common-text'>{inventory.asId}</p>
              <div className='InventoryDetailsModal__agglomeration-bar'>
                <div
                  className='InventoryDetailsModal__agglomeration-filling'
                  style={{ width: `${getDisplayedPercentage(inventory.occupiedPercentage)}` }}
                ></div>
              </div>
              <p className='InventoryDetailsModal__agglomeration-value'>
                {getDisplayedPercentage(inventory.occupiedPercentage)}
              </p>
              <p className='InventoryDetailsModal__agglomeration-value'>
                {inventory.allocatedSlots} / {inventory.totalSlots}
              </p>
            </div>
          ))}
        </>
      )}
    </Modal>
  )
}

export default InventoryDetailsModal
