import Button, { ButtonTheme } from 'components/Form/Button'
import FillingSpinner, { FillingSpinnerType } from 'components/FillingSpinner'
import MediaSelection from 'components/MediaSelection'
import Modal from 'components/Modal'
import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { DetailsValues } from 'contexts/CampaignFormContext'
import { MediaFormatIndoors } from 'types/campaign'
import { useTranslation } from 'react-i18next'
import Checkbox from 'components/Form/Checkbox'
import CreateTemplateModal from './CreateTemplateModal'
import TsxUtils from 'utils/tsx'
import './MediaSelectionModal.scss'
import { AppContext } from '../../../../../contexts/AppContext'
import { BaseRole } from '../../../../../types/user'
import useMediaSelection from '../../../../../hooks/useMediaSelection'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store'
import OfferFormService from '../services/offer-form.service'

type Props = {
  campaignId: string
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  mediumFormat: DetailsValues['mediumFormat']
  withFilters?: boolean
}

const MediaSelectionModal: React.FC<Props> = ({
  campaignId,
  mediumFormat,
  setIsOpen,
  isOpen,
  withFilters = true,
}) => {
  const { t } = useTranslation()
  const { userData } = useContext(AppContext)
  const { loading } = useMediaSelection({
    ids: { campaignId },
    mediumFormat,
    readOnly: false,
  })
  const { numberOfUsedAgglomerations } = useSelector((state: RootState) => state.campaignForm)
  const { detailsValues } = useSelector((state: RootState) => state.campaignForm.form)

  const [shouldSaveMedia, setShouldSaveMedia] = useState<boolean>(false)
  const [saveTemplate, setSaveTemplate] = useState<boolean>(false)
  const [templateMedia, setTemplateMedia] = useState<string[] | undefined>(undefined)

  const Footer = () => {
    return shouldSaveMedia ? (
      <FillingSpinner type={FillingSpinnerType.STANDARD} />
    ) : (
      <div className='Modal__footer MediaSelectionModal__footer'>
        {!loading &&
          OfferFormService.disableSave(
            userData.user?.roles,
            detailsValues.offer,
            numberOfUsedAgglomerations
          ) && (
            <div className='MediaSelectionModal__footer--error'>
              <p>
                {t('mediaSelection.template.agglomerations.missing')}
                {': '}
                {OfferFormService.minimumRequiredAgglomerations - numberOfUsedAgglomerations}
              </p>
            </div>
          )}
        <Button
          onClick={() => void setShouldSaveMedia(true)}
          disabled={
            shouldSaveMedia ||
            OfferFormService.disableSave(
              userData.user?.roles,
              detailsValues.offer,
              numberOfUsedAgglomerations
            )
          }
        >
          {t('common.save')}
        </Button>
        <Button
          theme={ButtonTheme.BLUE_OUTLINE}
          onClick={() => void setIsOpen(false)}
        >
          {t('common.back')}
        </Button>
        {userData.signedIn && userData.user?.roles.some(role => role.name === BaseRole.ADMIN) && (
          <Checkbox
            checked={saveTemplate}
            onChange={e => setSaveTemplate(e.target.checked)}
            className={'MediaSelectionModal__footer-checkbox'}
          >
            {t('mediaSelection.template.save')}
          </Checkbox>
        )}
      </div>
    )
  }

  if (templateMedia) {
    return (
      <CreateTemplateModal
        media={templateMedia}
        close={() => void setIsOpen(false)}
        goBack={() => void setTemplateMedia(undefined)}
      />
    )
  }

  return (
    <Modal
      classNameChildren={TsxUtils.joinClasses(
        'MediaSelectionModal',
        MediaFormatIndoors.includes(mediumFormat) && 'MediaSelectionModal--dg-in'
      )}
      title={t('mediaSelection.title')}
      isOpen={isOpen}
      onClose={setIsOpen}
      footer={Footer()}
      scrollable
    >
      <MediaSelection
        ids={{ campaignId }}
        mediumFormat={mediumFormat}
        shouldSave={shouldSaveMedia}
        readOnly={false}
        setShouldSave={setShouldSaveMedia}
        onSave={(mediaIds: string[]) => {
          if (saveTemplate) {
            setTemplateMedia(mediaIds)
          } else {
            setIsOpen(false)
          }
        }}
        withFilters={withFilters}
      />
    </Modal>
  )
}

export default MediaSelectionModal
