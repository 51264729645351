import { MediaFormat } from '../../../../../types/campaign'
import { MEDIA_DURATIONS } from '../../../../../constant'

export class MediaDurationHelper {
  /**
   * Function which tells what are available durations for given medium format
   *
   * @param mediumFormat
   */
  static get(mediumFormat: MediaFormat): number[] {
    const additionalDuration = 5

    // all dgIns have additional duration
    if (mediumFormat.includes('dgIn')) {
      return [additionalDuration, ...MEDIA_DURATIONS]
    }

    // dgCs has also an additional duration
    if (mediumFormat.includes('dgCs')) {
      return [additionalDuration, ...MEDIA_DURATIONS]
    }

    return [...MEDIA_DURATIONS]
  }
}
