import { client } from 'components/ApolloProvider'
import { Campaign } from 'types/campaign'
import { GET_MEDIA_REPORT } from './getMediaReport'
import {
  CampaignsCombinedReportResponse,
  FetchGeneratedReports,
  GetMediaReportAQR,
  ReportType,
} from './types'
import { GET_CAMPAIGNS_COMBINED_SUMMARY } from './getCampaignsCombinedSummary'
import { CREATE_CAMPAIGN_REPORT } from './createCampaignReport'
import { FETCH_GENERATED_REPORTS } from './fetchGeneratedReports'
import { FetchResult } from '@apollo/client'
import { CamelToSnakeCase, MutationStateResponse, PriceReportResponse } from '../../types/various'
import { CREATE_CREATION_REPORT } from './createCreationReport'
import { CREATE_MEDIA_REPORT } from './createMediaReport'
import { CREATE_MEDIUM_REPORT } from './createMediumReport'
import { CREATE_MEDIUM_CAMPAIGNS_REPORT } from './createMediumCampaignsReport'
import { FETCH_PRICING_REPORT } from './fetchPricingReport'

const reportApi = {
  getCampaignsCombinedReport: ({
    campaignIds,
    startDate,
    endDate,
  }: {
    campaignIds: Campaign['id'][]
    startDate: Campaign['startDate'] | null
    endDate: Campaign['endDate'] | null
  }): Promise<CampaignsCombinedReportResponse> => {
    return client.query({
      query: GET_CAMPAIGNS_COMBINED_SUMMARY,
      variables: { campaignIds, startDate, endDate },
    })
  },

  getMediaReport: ({
    asIds,
    startDate,
    endDate,
  }: {
    asIds: number[]
    startDate: Campaign['startDate'] | null
    endDate: Campaign['endDate'] | null
  }): Promise<GetMediaReportAQR> =>
    client.query({
      query: GET_MEDIA_REPORT,
      variables: { asIds, startDate, endDate },
    }),

  createCampaignReport: ({
    campaignId,
    startDate,
    endDate,
  }: {
    campaignId: Campaign['id']
    startDate: Campaign['startDate'] | null
    endDate: Campaign['endDate'] | null
  }): Promise<FetchResult<MutationStateResponse>> =>
    client.mutate({
      mutation: CREATE_CAMPAIGN_REPORT,
      variables: {
        campaignId,
        startDate,
        endDate,
      },
    }),

  createCreationReport: ({
    campaignId,
    startDate,
    endDate,
  }: {
    campaignId: Campaign['id']
    startDate: Campaign['startDate'] | null
    endDate: Campaign['endDate'] | null
  }): Promise<FetchResult<MutationStateResponse>> =>
    client.mutate({
      mutation: CREATE_CREATION_REPORT,
      variables: {
        campaignId,
        startDate,
        endDate,
      },
    }),

  createMediaReport: ({
    asIds,
    startDate,
    endDate,
  }: {
    asIds: string[]
    startDate: Campaign['startDate'] | null
    endDate: Campaign['endDate'] | null
  }): Promise<FetchResult<MutationStateResponse>> =>
    client.mutate({
      mutation: CREATE_MEDIA_REPORT,
      variables: {
        asIds,
        startDate,
        endDate,
      },
    }),

  createMediumReport: ({
    asId,
    startDate,
    endDate,
  }: {
    asId: string
    startDate: Campaign['startDate'] | null
    endDate: Campaign['endDate'] | null
  }): Promise<FetchResult<MutationStateResponse>> =>
    client.mutate({
      mutation: CREATE_MEDIUM_REPORT,
      variables: {
        asId,
        startDate,
        endDate,
      },
    }),

  createMediumCampaignsReport: ({
    asId,
    startDate,
    endDate,
  }: {
    asId: string
    startDate: Campaign['startDate'] | null
    endDate: Campaign['endDate'] | null
  }): Promise<FetchResult<MutationStateResponse>> =>
    client.mutate({
      mutation: CREATE_MEDIUM_CAMPAIGNS_REPORT,
      variables: {
        asId,
        startDate,
        endDate,
      },
    }),

  fetchGeneratedReports: (
    reportType: CamelToSnakeCase<ReportType>
  ): Promise<FetchGeneratedReports> =>
    client.query({
      query: FETCH_GENERATED_REPORTS,
      variables: { reportType },
    }),

  fetchPricingReport: (): Promise<FetchResult<PriceReportResponse>> =>
    client.query({
      query: FETCH_PRICING_REPORT,
    }),
}

export default reportApi
