import { AlertType } from '../../../../types/campaign'

export interface CampaignTableFiltersProps {
  onSubmit: () => void
}

export type FiltersForm = Filters & FiltersMeta

export interface Filters {
  alerts: AlertType[]
  cities: string[]
  agencies: string[]
  mediaFormats: string[]
  startDateFrom: string
  startDateTo: string
  endDateFrom: string
  endDateTo: string
  statuses: string[]
  text: string
  targets: string[]
  commercialAttributes: string[]
  buildings: string[]
}

export interface FiltersMeta {
  citiesAll: Filters['cities']
  agenciesAll: Filters['agencies']
  mediaFormatsAll: Filters['mediaFormats']
  statusesAll: Filters['statuses']
  alertsTypeAll: Filters['alerts']
  targetsAll: Filters['targets']
  commercialAttributesAll: Filters['commercialAttributes']
  buildingsAll: Filters['buildings']
}

export const filtersDefault: Filters = {
  agencies: [],
  alerts: [],
  cities: [],
  endDateFrom: '',
  endDateTo: '',
  mediaFormats: [],
  startDateFrom: '',
  startDateTo: '',
  statuses: [],
  text: '',
  targets: [],
  commercialAttributes: [],
  buildings: [],
}

export const filtersFormDefault: FiltersForm = {
  ...filtersDefault,
  citiesAll: [],
  agenciesAll: [],
  mediaFormatsAll: [],
  statusesAll: [],
  alertsTypeAll: [AlertType.alert, AlertType.info],
  targetsAll: [],
  commercialAttributesAll: [],
  buildingsAll: [],
}
